import { all, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  acceptTermsOfService as ACCEPT_TERMS_OF_SERVICE,
  addAttachmentToClaim as ADD_ATTACHMENT_TO_CLAIM,
  addCustomToFavorites as ADD_CUSTOM_TO_FAVORITES,
  addPet as ADD_PET,
  addressUpdated as ADDRESS_UPDATED,
  addToFavorites as ADD_TO_FAVORITES,
  applyAddressUpdate as APPLY_ADDRESS_UPDATE,
  bannerVisibleChanged as BANNER_VISIBLE_CHANGED,
  cancelAddressUpdate as CANCEL_ADDRESS_UPDATE,
  changeName as CHANGE_NAME,
  checkIfHasPrimaryVet as CHECK_IF_HAS_PRIMARY_VET,
  checkPasswordSetup as CHECK_PASSWORD_SETUP,
  claimGetPolicyByDate as CLAIM_GET_POLICY_BY_DATE,
  clearAllCustomerCache as CLEAR_ALL_CUSTOMER_CACHE,
  clearUserCache as CLEAR_USER_CACHE,
  contactPetFamily as CONTACT_PET_FAMILY,
  convertImpersonateCustomerId as CONVERT_IMPERSONATE_CUSTOMER_ID,
  createClaim as CREATE_CLAIM,
  createFolder as CREATE_FOLDER,
  createOneIncSession as CREATE_ONE_INC_SESSION,
  deleteFiles as DELETE_FILES,
  deleteFolder as DELETE_FOLDER,
  deletePet as DELETE_PET,
  deleteTagNumber as DELETE_TAG_NUMBER,
  doLogin as DO_LOGIN,
  doLoginImpersonate as DO_LOGIN_IMPERSONATE,
  doLogout as DO_LOGOUT,
  doResetPassword as DO_RESET_PASSWORD,
  downloadFiles as DOWNLOAD_FILES,
  editPet as EDIT_PET,
  getAllFavorites as GET_ALL_FAVORITES,
  getChangeCoverageMultimodal as GET_CHANGE_COVERAGE_MULTIMODAL,
  getChangeCoveragePreview as GET_CHANGE_COVERAGE_PREVIEW,
  getClaimAttachments as GET_CLAIM_ATTACHMENTS,
  getClaimById as GET_CLAIM_BY_ID,
  getClaimByIdRequested as GET_CLAIM_BY_ID_REQUESTED,
  getClaims as GET_CLAIMS,
  getContactBrandData as GET_CONTACT_BRAND_DATA,
  getGeneralData as GET_GENERAL_DATA,
  getNewPasswordToken as GET_NEW_PASSWORD_TOKEN,
  getPetTagInfo as GET_PET_TAG_INFO,
  getPrivateToken as GET_PRIVATE_TOKEN,
  getPublicSideImage as GET_PUBLIC_SIDE_IMAGE,
  getRecentFiles as GET_RECENT_FILES,
  getSharePostData as GET_SHARE_POST_DATA,
  getTemporaryToken as GET_TEMPORARY_TOKEN,
  getWaitingPeriods as GET_WAITING_PERIODS,
  loadBreedList as LOAD_BREED_LIST,
  loadClaimSteps as LOAD_CLAIM_STEPS,
  loadAdditionalClaimbotSteps as LOAD_ADDITIONAL_CLAIM_STEPS,
  loadDataForPrivateToken as LOAD_DATA_FORM_PRIVATE_TOKEN,
  loadHasFigoClaims as LOAD_HAS_FIGO_CLAIMS,
  loadCustomerInformation as LOAD_CUSTOMER_INFORMATION,
  loadFolderContent as LOAD_FOLDER_CONTENT,
  loadFolderStructure as LOAD_FOLDER_STRUCTURE,
  loadMicrochipData as LOAD_MICROCHIP_DATA,
  loadMultiModalData as LOAD_MULTI_MODAL_DATA,
  loadNewClaimSteps as LOAD_NEW_CLAIM_STEPS,
  loadPaymentHistory as LOAD_PAYMENT_HISTORY,
  loadPetProfile as LOAD_PET_PROFILE,
  loadPlanSummaryDetails as LOAD_PLAN_SUMMARY_DETAILS,
  loadPlanSummarySeeMoreInfo as LOAD_PLAN_SUMMARY_SEE_MORE_INFO,
  loadPoliciesOverview as LOAD_POLICIES_OVERVIEW,
  loadPoliciesPaymentNumber as LOAD_POLICIES_PAYMENT_NUMBER,
  loadPolicyDetails as LOAD_POLICY_DETAILS,
  loadPolicyNotifications as LOAD_POLICY_NOTIFICATIONS,
  loadReimbursementMethod as LOAD_REIMBURSEMENT_METHOD,
  loadWhatWeCoverInfo as LOAD_WHAT_WE_COVER_INFO,
  loadZipCodeDetails as LOAD_ZIP_CODE_DETAILS,
  logIn as LOG_IN,
  markFilesAsRead as MARK_FILES_AS_READ,
  moveFiles as MOVE_FILES,
  moveFolder as MOVE_FOLDER,
  namesUpdated as NAMES_UPDATED,
  oneIncLoadDefaultPayments as ONE_INC_LOAD_DEFAULT_PAYMENTS,
  openMediaFileToBlob as OPEN_MEDIA_FILE_TO_BLOB,
  phonesUpdated as PHONES_UPDATED,
  rateCoverage as RATE_COVERAGE,
  recoverPassword as RECOVER_PASSWORD,
  refreshFolderContent as REFRESH_FOLDER_CONTENT,
  refreshPersonalInformation as REFRESH_PERSONAL_INFORMATION,
  refreshPets as REFRESH_PETS,
  refreshPolicies as REFRESH_POLICIES,
  refreshToken as REFRESH_TOKEN,
  removePetPhoto as REMOVE_PET_PHOTO,
  removeVet as REMOVE_VET,
  requestClaimsResults as REQUEST_CLAIMS_RESULTS,
  saveMicrochip as SAVE_MICROCHIP,
  savePrimaryVet as SAVE_PRIMARY_VET,
  saveUserInfo as SAVE_USER_INFO,
  searchPetTag as SEARCH_PET_TAG,
  searchPrimaryVet as SEARCH_PRIMARY_VET,
  searchVets as SEARCH_VETS,
  sendLinkMessage as SEND_LINK_MESSAGE,
  setPetTagInfo as SET_PET_TAG_INFO,
  setUpNewPassword as SET_UP_NEW_PASSWORD,
  showViewFile as SHOW_VIEW_FILE,
  startup as STARTUP,
  submitChangeCoverage as SUBMIT_CHANGE_COVERAGE,
  submitClaim as SUBMIT_CLAIM,
  tagPetFile as TAG_PET_FILE,
  tokenCustomerLoaded as TOKEN_CUSTOMER_LOADED,
  updateAddress as UPDATE_ADDRESS,
  updateEmail as UPDATE_EMAIL,
  updateNames as UPDATE_NAMES,
  updatePassword as UPDATE_PASSWORD,
  updatePaymentMethod as UPDATE_PAYMENT_METHOD,
  updatePetCloudEmail as UPDATE_PET_CLOUD_EMAIL,
  updatePhones as UPDATE_PHONES,
  updatePowerUp as UPDATE_POWER_UP,
  updateReimbursementMethod as UPDATE_REIMBURSEMENT_METHOD,
  uploadFiles as UPLOAD_FILES,
  validateChangeCoverage as VALIDATE_CHANGE_COVERAGE,
  validateZipCode as VALIDATE_ZIP_CODE,
  validResetPasswordToken as VALID_RESET_PASSWORD_TOKEN,
} from './actions';
import {
  acceptTermsOfService,
  addAttachmentToClaim,
  addCustomToFavorites,
  addPet,
  addToFavorites,
  applyAddressUpdate,
  billingSaga,
  cancelAddressUpdate,
  changeName,
  checkIfHasPrimaryVet,
  checkPasswordSetup,
  claimGetPolicyByDate,
  clearAllCustomerCache,
  clearUserCache,
  contactPetFamily,
  convertCustomerId,
  createClaim,
  createFolder,
  createOneIncSession,
  deleteFigoTagNumber,
  deleteFiles,
  deleteFolder,
  deletePet,
  doBannerVisibleChanged,
  doLogin,
  doLoginImpersonate,
  doLogout,
  doPetLoad,
  doResetPassword,
  doSearchPetTag,
  doSocialProfileLoad,
  downloadFiles,
  editPet,
  fetchClaimById,
  fetchClaimsResults,
  getAllFavorites,
  getAttachmentsByClaim,
  getBillingInfo,
  getChangeCoverageMultimodal,
  getChangeCoveragePreview,
  getClaimByNumber,
  getClaimsUser,
  getContactBrandData,
  getFigoPetTagInfo,
  getGeneralData,
  getNewPasswordToken,
  getPaymentHistory,
  getPetProfile,
  getPoliciesPaymentNumber,
  getPolicyInfo,
  getPolicySummary,
  getPrivateToken,
  getPublicSideImage,
  getRecentFiles,
  getSharePostData,
  getStates,
  getTemporaryToken,
  getUserInformation,
  getUserTermsOfService,
  getWaitingPeriods,
  homeSaga,
  loadAdditionalClaimbotSteps,
  loadBlobFile,
  loadBreedList,
  loadCachedBannerVisible,
  loadClaimSteps,
  loadCustomerInformation,
  loadDataPrivateToken,
  loadDefaultpayments,
  loadFolderContent,
  loadFolderStructure,
  loadHasFigoClaims,
  loadMicrochipData,
  loadMultiModalData,
  loadNewClaimSteps,
  loadPlanSummarySeeMoreInfo,
  loadPoliciesOverview,
  loadPolicyBenefits,
  loadPolicyNotifications,
  loadReimbursementMethod,
  loadWhatWeCoverInfo,
  loadZipCodeDetails,
  markFilesAsRead,
  moveFiles,
  moveFolder,
  multibrandSaga,
  openMediaFileToBlob,
  policiesSaga,
  rateCoverage,
  recoverPassword,
  refreshToken,
  removePetPhoto,
  removeVet,
  saveMicrochip,
  savePrimaryVet,
  searchPrimaryVets,
  searchVets,
  sendLinkMessage,
  setFigoPetTagInfo,
  settingsSaga,
  setUpNewPassword,
  submitChangeCoverage,
  submitClaim,
  syncUserInformationStoreWithRedux,
  tagPetFile,
  updateAddress,
  updateEmail,
  updateNames,
  updatePassword,
  updatePaymentMethod,
  updatePetCloudEmail,
  updatePhones,
  updatePowerUp,
  updateReimbursementMethod,
  uploadFilesPerBatch,
  validateChangeCoverage,
  validateZipCode,
  validResetPasswordToken,
} from './sagas';

export default function* sagas(store) {
  yield all([
    takeLatest(STARTUP, loadCachedBannerVisible),
    takeLatest(STARTUP, getUserInformation, store.dispatch),
    takeLatest(STARTUP, getUserTermsOfService, store.dispatch),
    takeLatest(STARTUP, getPolicySummary, store.dispatch),
    takeLatest(GET_PUBLIC_SIDE_IMAGE, getPublicSideImage, store.dispatch),
    takeLatest(DO_LOGIN, doLogin),
    takeLatest(DO_LOGIN_IMPERSONATE, doLoginImpersonate),
    takeLatest(DO_LOGOUT, doLogout, store.dispatch),
    takeLatest(DO_LOGIN, loadCachedBannerVisible),
    takeLatest(ADDRESS_UPDATED, syncUserInformationStoreWithRedux),
    takeLatest(NAMES_UPDATED, syncUserInformationStoreWithRedux),
    takeLatest(LOAD_POLICY_DETAILS, getPolicyInfo, store.dispatch),
    takeLatest(LOAD_POLICY_DETAILS, getBillingInfo, store.dispatch),
    takeLatest(LOAD_ZIP_CODE_DETAILS, loadZipCodeDetails, store.dispatch),
    takeLatest(PHONES_UPDATED, syncUserInformationStoreWithRedux),
    takeLatest(LOAD_PAYMENT_HISTORY, getPaymentHistory, store.dispatch),
    takeLatest(RECOVER_PASSWORD, recoverPassword, store.dispatch),
    takeLatest(SAVE_USER_INFO, getStates, store.dispatch),
    takeLatest(SAVE_USER_INFO, doSocialProfileLoad, store.dispatch),
    takeLatest(LOG_IN, getPolicySummary, store.dispatch),
    takeLatest(
      LOAD_CUSTOMER_INFORMATION,
      loadCustomerInformation,
      store.dispatch,
    ),
    takeLatest(CHECK_PASSWORD_SETUP, checkPasswordSetup),
    takeLatest(GET_NEW_PASSWORD_TOKEN, getNewPasswordToken),
    takeLatest(SET_UP_NEW_PASSWORD, setUpNewPassword, store.dispatch),
    takeLatest(UPDATE_ADDRESS, updateAddress, store.dispatch),
    takeLatest(UPDATE_NAMES, updateNames, store.dispatch),
    takeLatest(UPDATE_PHONES, updatePhones, store.dispatch),
    takeLatest(UPDATE_EMAIL, updateEmail, store.dispatch),
    takeLatest(UPDATE_PET_CLOUD_EMAIL, updatePetCloudEmail, store.dispatch),
    takeLatest(UPDATE_PASSWORD, updatePassword, store.dispatch),
    takeLatest(
      LOAD_REIMBURSEMENT_METHOD,
      loadReimbursementMethod,
      store.dispatch,
    ),
    takeLatest(CANCEL_ADDRESS_UPDATE, cancelAddressUpdate, store.dispatch),
    takeLatest(APPLY_ADDRESS_UPDATE, applyAddressUpdate, store.dispatch),
    takeLatest(
      UPDATE_REIMBURSEMENT_METHOD,
      updateReimbursementMethod,
      store.dispatch,
    ),
    takeLatest(UPDATE_PAYMENT_METHOD, updatePaymentMethod, store.dispatch),
    takeLatest(
      LOAD_PLAN_SUMMARY_DETAILS,
      loadPolicyBenefits,
      store.dispatch,
    ),
    takeLatest(
      LOAD_PLAN_SUMMARY_SEE_MORE_INFO,
      loadPlanSummarySeeMoreInfo,
      store.dispatch,
    ),
    takeLatest(LOAD_POLICIES_OVERVIEW, loadPoliciesOverview, store.dispatch),
    takeLatest(LOAD_MULTI_MODAL_DATA, loadMultiModalData, store.dispatch),
    takeLatest(
      LOAD_POLICY_NOTIFICATIONS,
      loadPolicyNotifications,
      store.dispatch,
    ),
    takeLatest(LOAD_WHAT_WE_COVER_INFO, loadWhatWeCoverInfo, store.dispatch),
    takeLatest(GET_CLAIMS, getClaimsUser, store.dispatch),
    takeLatest(LOAD_NEW_CLAIM_STEPS, loadNewClaimSteps, store.dispatch),
    takeLatest(LOAD_CLAIM_STEPS, loadClaimSteps, store.dispatch),
    takeLatest(
      LOAD_ADDITIONAL_CLAIM_STEPS,
      loadAdditionalClaimbotSteps,
      store.dispatch,
    ),
    takeLatest(
      CLAIM_GET_POLICY_BY_DATE,
      claimGetPolicyByDate,
      store.dispatch,
    ),
    takeLatest(LOAD_HAS_FIGO_CLAIMS, loadHasFigoClaims, store.dispatch),
    takeLatest(SUBMIT_CLAIM, submitClaim, store.dispatch),
    takeEvery(REQUEST_CLAIMS_RESULTS, fetchClaimsResults),
    takeLatest(GET_CLAIM_BY_ID_REQUESTED, fetchClaimById, store.dispatch),
    takeLatest(GET_CLAIM_ATTACHMENTS, getAttachmentsByClaim, store.dispatch),
    takeLatest(GET_CLAIM_BY_ID, getClaimByNumber, store.dispatch),
    takeLatest(CREATE_CLAIM, createClaim, store.dispatch),
    takeLatest(
      ADD_ATTACHMENT_TO_CLAIM,
      addAttachmentToClaim,
      store.dispatch,
    ),
    takeLatest(
      LOAD_POLICIES_PAYMENT_NUMBER,
      getPoliciesPaymentNumber,
      store.dispatch,
    ),
    takeLatest(SEND_LINK_MESSAGE, sendLinkMessage, store.dispatch),
    takeLatest(LOAD_BREED_LIST, loadBreedList, store.dispatch),
    takeLatest(LOAD_MICROCHIP_DATA, loadMicrochipData, store.dispatch),
    takeLatest(SAVE_MICROCHIP, saveMicrochip, store.dispatch),
    takeLatest(LOAD_PET_PROFILE, getPetProfile, store.dispatch),
    takeLatest(SEARCH_PET_TAG, doSearchPetTag, store.dispatch),
    takeLatest(BANNER_VISIBLE_CHANGED, doBannerVisibleChanged, store.dispatch),
    takeLatest(DO_RESET_PASSWORD, doResetPassword, store.dispatch),
    takeLatest(GET_PRIVATE_TOKEN, getPrivateToken, store.dispatch),
    takeLatest(GET_TEMPORARY_TOKEN, getTemporaryToken, store.dispatch),
    takeLatest(
      VALID_RESET_PASSWORD_TOKEN,
      validResetPasswordToken,
      store.dispatch,
    ),
    takeLatest(CONTACT_PET_FAMILY, contactPetFamily, store.dispatch),
    takeLatest(CREATE_ONE_INC_SESSION, createOneIncSession, store.dispatch),
    takeLatest(
      ONE_INC_LOAD_DEFAULT_PAYMENTS,
      loadDefaultpayments,
      store.dispatch,
    ),
    takeLatest(
      LOAD_DATA_FORM_PRIVATE_TOKEN,
      loadDataPrivateToken,
      store.dispatch,
    ),
    takeLatest(REFRESH_TOKEN, refreshToken, store.dispatch),
    takeLatest(
      REFRESH_PERSONAL_INFORMATION,
      getUserInformation,
      store.dispatch,
    ),
    takeLatest(
      CONVERT_IMPERSONATE_CUSTOMER_ID,
      convertCustomerId,
      store.dispatch,
    ),
    takeLatest(
      ACCEPT_TERMS_OF_SERVICE,
      acceptTermsOfService,
      store.dispatch,
    ),
    takeLatest(CLEAR_ALL_CUSTOMER_CACHE, clearAllCustomerCache, store.dispatch),
    takeLatest(CLEAR_USER_CACHE, clearUserCache, store.dispatch),
    takeLatest(GET_WAITING_PERIODS, getWaitingPeriods, store.dispatch),
    takeLatest(
      GET_CHANGE_COVERAGE_PREVIEW,
      getChangeCoveragePreview,
      store.dispatch,
    ),
    takeLatest(RATE_COVERAGE, rateCoverage, store.dispatch),
    takeLatest(
      GET_CHANGE_COVERAGE_MULTIMODAL,
      getChangeCoverageMultimodal,
      store.dispatch,
    ),
    takeLatest(UPDATE_POWER_UP, updatePowerUp, store.dispatch),
    takeLatest(SUBMIT_CHANGE_COVERAGE, submitChangeCoverage, store.dispatch),
    takeLatest(GET_SHARE_POST_DATA, getSharePostData, store.dispatch),
    takeLatest(GET_GENERAL_DATA, getGeneralData),

    // Policies
    takeLatest(
      VALIDATE_CHANGE_COVERAGE,
      validateChangeCoverage,
      store.dispatch,
    ),
    takeLatest(
      REFRESH_POLICIES,
      getPolicySummary,
      store.dispatch,
    ),

    // After load page
    takeLatest(TOKEN_CUSTOMER_LOADED, doPetLoad, store.dispatch),

    // My pets
    takeLatest(ADD_PET, addPet, store.dispatch),
    takeLatest(EDIT_PET, editPet, store.dispatch),
    takeLatest(REFRESH_PETS, doPetLoad, store.dispatch),
    takeLatest(REFRESH_PETS, getPolicySummary, store.dispatch),
    takeLatest(REFRESH_PETS, getPetProfile, store.dispatch),
    takeLatest(GET_PET_TAG_INFO, getFigoPetTagInfo, store.dispatch),
    takeLatest(SET_PET_TAG_INFO, setFigoPetTagInfo, store.dispatch),
    takeLatest(DELETE_TAG_NUMBER, deleteFigoTagNumber, store.dispatch),
    takeLatest(DELETE_PET, deletePet, store.dispatch),
    takeLatest(REMOVE_PET_PHOTO, removePetPhoto, store.dispatch),

    // Inbox and docs
    takeLatest(DOWNLOAD_FILES, downloadFiles, store.dispatch),
    takeLatest(GET_RECENT_FILES, getRecentFiles, store.dispatch),
    takeLatest(LOAD_FOLDER_CONTENT, loadFolderContent, store.dispatch),
    takeLatest(LOAD_FOLDER_STRUCTURE, loadFolderStructure, store.dispatch),
    takeLatest(MARK_FILES_AS_READ, markFilesAsRead, store.dispatch),
    takeLatest(MOVE_FILES, moveFiles, store.dispatch),
    takeLatest(TAG_PET_FILE, tagPetFile, store.dispatch),
    takeLatest(CHANGE_NAME, changeName, store.dispatch),
    takeLatest(REFRESH_FOLDER_CONTENT, loadFolderContent, store.dispatch),
    takeLatest(CREATE_FOLDER, createFolder, store.dispatch),
    takeLatest(DELETE_FOLDER, deleteFolder, store.dispatch),
    takeLatest(DELETE_FILES, deleteFiles, store.dispatch),
    takeLatest(UPLOAD_FILES, uploadFilesPerBatch, store.dispatch),
    takeLatest(MOVE_FOLDER, moveFolder, store.dispatch),
    takeLatest(SHOW_VIEW_FILE, loadBlobFile, store.dispatch),
    takeLatest(OPEN_MEDIA_FILE_TO_BLOB, openMediaFileToBlob, store.dispatch),

    // My Vets
    takeLatest(SEARCH_VETS, searchVets, store.dispatch),
    takeLatest(VALIDATE_ZIP_CODE, validateZipCode),
    takeLatest(ADD_CUSTOM_TO_FAVORITES, addCustomToFavorites, store.dispatch),
    takeLatest(ADD_TO_FAVORITES, addToFavorites, store.dispatch),
    takeLatest(GET_ALL_FAVORITES, getAllFavorites, store.dispatch),
    takeLatest(REMOVE_VET, removeVet, store.dispatch),
    takeLatest(SEARCH_PRIMARY_VET, searchPrimaryVets),
    takeLatest(CHECK_IF_HAS_PRIMARY_VET, checkIfHasPrimaryVet, store.dispatch),
    takeLatest(SAVE_PRIMARY_VET, savePrimaryVet, store.dispatch),

    spawn(billingSaga, store),
    spawn(homeSaga, store),
    spawn(settingsSaga, store),
    spawn(policiesSaga, store),
    spawn(multibrandSaga, store),

    takeLatest(GET_CONTACT_BRAND_DATA, getContactBrandData, store.dispatch),
  ]);
}
