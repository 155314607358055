import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../common/petCloud';

import { useDirectDepositContent } from './hooks/useDirectDepositContent';

import '../css/ReimbursementEditModal.css';

const EditReimbursementModalButtons = ({ onCancel = () => { } }) => {
  const { t } = useTranslation('common');
  const {
    handleEditReimbursementAccept,
    handleEditReimbursementCancel,
    handleEditReimbursementSuccess,
    isBankFormInvalid,
    reimbursementMethodUpdateSuccess,
    updatingReimbursementMethod,
  } = useDirectDepositContent({ onCancel });

  return (
    <div className="Reimbursement-edit-modal-footer-buttons-container">
      <ActionButton
        disabled={isBankFormInvalid()}
        loading={updatingReimbursementMethod}
        onClick={handleEditReimbursementAccept}
        onSuccess={handleEditReimbursementSuccess}
        success={reimbursementMethodUpdateSuccess}
        title={t('saveButton')}
      />

      <ActionButton
        isSecondaryButton
        onClick={handleEditReimbursementCancel}
        title={t('cancelButton')}
      />
    </div>
  );
};

export { EditReimbursementModalButtons };
