import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFeatureFlags } from '../actions';
import FigoLoadingOverlay from '../components/common/FigoLoadingOverlay';

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
  const {
    flags,
    loading,
  } = useSelector(({ settings }) => settings.featureFlags, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeatureFlags());
  }, [dispatch]);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {loading ? <FigoLoadingOverlay visible /> : null}
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useFeatureFlag = (featureKey) => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw Error('Feature Flag Context not found');
  }

  const featureFlags = context;
  const featureFlag = useMemo(() => !!featureFlags[featureKey], []);
  return featureFlag;
};

const useShowFF = () => useFeatureFlag('PetCloudShowFF');
const useSubmitRequestAccountChanges =
  () => useFeatureFlag('PetCloudSubmitForm');
const usePetProtectCopay = () => useFeatureFlag('PetCloudPetProtectCopay');
const useWebViewReskin = () => useFeatureFlag('PetCloudWebViewReskin');
const useCustomerApiV1 = () => useFeatureFlag('PetCloudCustomerApiV1');
const useClaimsApiV1 = () => useFeatureFlag('PetCloudClaimsApiV1');
const useCustomerInboxDocsApiV2 = () => useFeatureFlag(
  'PetCloudCustomerApiV2InboxAndDocs',
);
const useClaimApiV2 = () => useFeatureFlag('PetCloudClaimsApiV2');
const useCustomerPets150617V1 = () => useFeatureFlag(
  'PetCloudCustomer150617ApiV1',
);

export {
  useClaimApiV2,
  useClaimsApiV1,
  useCustomerApiV1,
  useCustomerInboxDocsApiV2,
  useCustomerPets150617V1,
  usePetProtectCopay,
  useShowFF,
  useSubmitRequestAccountChanges,
  useWebViewReskin,
};
