import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import {
  doLogin,
  doLoginImpersonate,
} from '../../actions';
import { useB2CInfo, useQuery } from '../../hooks';
import { isMobile, useMultipleScopes } from '../../services/utils';

import FigoLoadingOverlay from '../common/FigoLoadingOverlay';
import { LoginMobile } from './LoginMobile';

import { b2cPolicies, loginRequestScopes } from '../../authConfigB2C';

import { saveData } from '../../services/api';
import { IMPERSONATE_EMAIL } from '../../services/constants';

import './LoginScreen.css';

const LoginScreen = ({ isImpersonate }) => {
  const store = useSelector(({ session }) => session, shallowEqual);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const query = useQuery();
  const loginHintParam = query.get('login_hint');
  const targetEmailParam = query.get('target_email');
  const scopes = useMultipleScopes ? loginRequestScopes : {};

  const requestOptions = isImpersonate
    ? {
      ...scopes,
      ...b2cPolicies.authorities.impersonation,
      extraQueryParameters: {
        targetEmail: targetEmailParam,
      },
      loginHint: loginHintParam,
    }
    : {
      ...scopes,
      ...b2cPolicies.authorities.signUpSignIn,
    };
  const { result } =
    useMsalAuthentication(InteractionType.Redirect, requestOptions);

  const isAuthenticated = useIsAuthenticated();
  const { logoutB2C } = useB2CInfo();

  const { error, loading } = store;

  useEffect(() => {
    if (email) {
      setEmail(email.trim());
    }
  }, [email]);

  useEffect(() => {
    if (loginHintParam) {
      saveData(IMPERSONATE_EMAIL, loginHintParam);
    }
  }, [loginHintParam]);

  useEffect(() => {
    if (error) {
      if (isAuthenticated) {
        logoutB2C();
      }
    }
  }, [error, isAuthenticated]);

  const signInUser = useCallback((tokenResponse) => {
    let impersonatedUser = null;

    if (tokenResponse) {
      impersonatedUser = tokenResponse?.account
        ?.idTokenClaims?.impersonatedUser;
    }

    const action = impersonatedUser ? doLoginImpersonate : doLogin;

    if (tokenResponse) {
      dispatch(action({
        b2cToken: tokenResponse.accessToken
          || tokenResponse.idToken,
        CustomerGuid: '',
        email,
        keepSigned: false,
        password: '',
      }));
    }
  }, [email]);

  useEffect(() => {
    if (result) {
      signInUser(result);
    }
  }, [result]);

  if (isMobile()) {
    return <LoginMobile />;
  }

  return (
    <>
      <FigoLoadingOverlay visible={!result || loading} />
    </>
  );
};

export { LoginScreen };
