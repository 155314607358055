import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import closeIcon from '../../../assets/close-button.svg';
import arrowIcon from '../../../assets/back-arrow.svg';
import { getThemeLogoById } from '../../../theme/theme.utils';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';

import ModalContainer from '../ModalContainer';
import './ProvidersSelector.css';

const ProviderListItem = ({
  marketingChannelId = MARKETING_CHANNEL_ID.figo,
  onClick = () => { },
}) => {
  const logo = useMemo(() => {
    const theme = getThemeLogoById(marketingChannelId);
    return theme.dark;
  }, [marketingChannelId]);

  const handleClick = (providerId) => () => {
    onClick(providerId);
  };

  return (
    <li>
      <button onClick={handleClick(marketingChannelId)} type="button">
        <img
          alt=""
          className={`Provider-selector-${marketingChannelId}`}
          src={logo}
        />

        <img alt="" className="Provider-selector-go-icon" src={arrowIcon} />
      </button>
    </li>
  );
};

export const ProvidersSelector = ({
  onClose = () => { },
  onProviderSelected = () => { },
  show = false,
}) => {
  const { t } = useTranslation('common');
  const store = useSelector(({ personalInformation }) => ({
    ...personalInformation.userInfo,
  }), shallowEqual);
  const { Companies: companies } = store;

  return (
    <ModalContainer
      cancelable
      contentStyle="Provider-selector"
      customCloseIcon={closeIcon}
      handleOutClick={onClose}
      show={show}
    >
      <h2 className="Provider-selector-title">{t('providerSelector.title')}</h2>

      <p className="Provider-selector-desc">
        {t('providerSelector.description')}
      </p>

      <ul className="Provider-selector-list">
        {companies.map((company) => (
          <ProviderListItem
            key={company.Id}
            marketingChannelId={company.Id}
            onClick={onProviderSelected}
          />
        ))}
      </ul>
    </ModalContainer>
  );
};
