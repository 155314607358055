import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  getQuoteValidation: {
    hasMultipleOrcaPolicies: false,
    loading: false,
    petValidBreed: '',
    policyProvider: '',
    requested: false,
  },
  powerUpDetails: null,
  powerUpDetailsLoading: false,
};

export default handleActions({
  BREED_MULTIBRAND_VALIDATION_FAILED: (state) => ({
    ...state,
    getQuoteValidation: {
      ...state.getQuoteValidation,
      hasMultipleOrcaPolicies: false,
      loading: false,
      petValidBreed: '',
      policyProvider: null,
      requested: false,
    },
  }),
  BREED_MULTIBRAND_VALIDATION_SUCCEED: (state, { payload }) => ({
    ...state,
    getQuoteValidation: {
      ...state.getQuoteValidation,
      hasMultipleOrcaPolicies: payload.hasMultipleOrcaPolicies,
      loading: false,
      petValidBreed: payload.petValidBreed,
      policyProvider: payload.policyProvider,
      requested: true,
    },
  }),
  ORCA_GET_POWER_UP_ATTEMPT: (state) => ({
    ...state,
    powerUpDetails: null,
    powerUpDetailsLoading: true,
  }),
  ORCA_GET_POWER_UP_FAILED: (state) => ({
    ...state,
    powerUpDetailsLoading: false,
  }),
  ORCA_GET_POWER_UP_LOADED: (state, { payload }) => ({
    ...state,
    powerUpDetails: payload,
    powerUpDetailsLoading: false,
  }),
  RESET_VALIDATION_BREED_MULTIBRAND: (state) => ({
    ...state,
    getQuoteValidation: {
      ...INITIAL_STATE.getQuoteValidation,
    },
  }),
  VALIDATE_BREED_MULTIBRAND: (state) => ({
    ...state,
    getQuoteValidation: {
      ...state.getQuoteValidation,
      hasMultipleOrcaPolicies: false,
      loading: true,
      petValidBreed: '',
      policyProvider: null,
      requested: false,
    },
  }),
}, INITIAL_STATE);
