/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { EditReimbursementModalButtons } from './EditReimbursementModalButtons';

import '../css/CheckContent.css';

const CheckContent = ({ onCancel = () => { } }) => {
  const { t } = useTranslation('myAccount');
  const store = useSelector(({ personalInformation }) => ({
    personalInformation,
  }), shallowEqual);
  const { personalInformation: { userInfo } } = store;

  const addressValue = useMemo(() => `${userInfo.Address.Line1} ${userInfo.Address.Line2 ? `,${userInfo.Address.Line2}` : ''}
    ${userInfo.Address.City}, ${userInfo.Address.StateId} ${userInfo.Address.ZipCode}`,
  [userInfo.Address]);

  return (
    <div>
      <h6 className="Check-content-title">
        {t('reimbursementSection.editModal.addressOnFile')}
      </h6>

      <p className="Check-content-address">{addressValue}</p>

      <p className="Check-content-footer-text">
        {t('reimbursementSection.editModal.appliedToAllPolicies')}
      </p>

      <EditReimbursementModalButtons onCancel={onCancel} />
    </div>
  );
};

export { CheckContent };
