import { useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  changeReimbursementAccountNumber,
  changeReimbursementAccountType,
  changeReimbursementConfirmAccountNumber,
  changeReimbursementRoutingNumber,
  changeRoutingNumberError,
  closeEditReimbursementMethodModal,
  updateReimbursementMethod,
} from '../../../../../actions';
import { REIMBURSEMENT_METHODS } from '../../../../../constants';
import { delay } from '../../../../../services/utils';

const CHECK_REIMBURSEMENT = 1;
const DOWN_KEY = 40;
const UP_KEY = 38;

const useDirectDepositContent = ({ onCancel = () => { } }) => {
  const dispatch = useDispatch();
  const routingNumberRef = useRef(null);
  const bankAccountRef = useRef(null);
  const confirmAccountRef = useRef(null);
  const [accountNumberError, setAccountNumberError] = useState(null);
  const [
    confirmAccountNumberError,
    setConfirmAccountNumberError,
  ] = useState(null);

  const store = useSelector(({
    billingPayments,
    personalInformation,
  }) => ({ billingPayments, personalInformation }), shallowEqual);
  const { billingPayments } = store;
  const {
    reimbursementData,
    reimbursementMethodUpdateSuccess,
    routingNumberError,
    updatingReimbursementMethod,
  } = billingPayments;

  function handleEditReimbursementAccept() {
    if (billingPayments.updatingReimbursementMethod) {
      return;
    }

    const reimbursementMethod = reimbursementData.Type === CHECK_REIMBURSEMENT
      ? { Type: CHECK_REIMBURSEMENT }
      : reimbursementData;

    dispatch(updateReimbursementMethod(reimbursementMethod));
  }

  async function handleEditReimbursementSuccess() {
    await delay(500);
    dispatch(closeEditReimbursementMethodModal());
  }

  const handleAccountTypeChange = (type) => () => {
    dispatch(changeReimbursementAccountType(type));
  };

  const handleArrowKeyPressed = () => (event) => {
    const { keyCode } = event;

    switch (keyCode) {
      case UP_KEY:
      case DOWN_KEY:
        event.preventDefault();
        break;
      default: break;
    }
  };

  // For more information about the Routing validation see:
  // http://en.wikipedia.org/wiki/Routing_transit_number
  function isRoutingNumberValid(routing) {
    if (routing.length !== 9) {
      return 'errors.mustBeANineDigitNumber';
    }

    const checksumTotal =
      (
        7 * (parseInt(routing.charAt(0), 10)
          + parseInt(routing.charAt(3), 10)
          + parseInt(routing.charAt(6), 10))
      )
      + (
        3 * (parseInt(routing.charAt(1), 10)
          + parseInt(routing.charAt(4), 10)
          + parseInt(routing.charAt(7), 10))
      )
      + (
        9 * (parseInt(routing.charAt(2), 10)
          + parseInt(routing.charAt(5), 10)
          + parseInt(routing.charAt(8), 10))
      );

    const checksumMod = checksumTotal % 10;
    if (checksumTotal === 0 || checksumMod !== 0) {
      return 'errors.invalidBankRoutingNumber';
    }

    return '';
  }

  function validateNumbersOnly(input) {
    const ONLY_NUMBER_REGEX = /^\d+$/g;

    if (input && input.match(ONLY_NUMBER_REGEX)) {
      return '';
    }

    return 'errors.mustContainNumbersOnly';
  }

  function isBankFormInvalid() {
    if (reimbursementData.Type === REIMBURSEMENT_METHODS.check) {
      return false;
    }

    if (billingPayments.routingNumberError
      || accountNumberError
      || confirmAccountNumberError
      || !reimbursementData.AccountNumber
      || !reimbursementData.ConfirmAccountNumber
      || !reimbursementData.RoutingNumber) {
      return true;
    }

    return false;
  }

  function validateRoutingNumber(routingNumber, formValidation) {
    const numberValidation = validateNumbersOnly(routingNumber);

    if (formValidation && !numberValidation) {
      const routingNumValidation = isRoutingNumberValid(routingNumber);

      if (routingNumValidation) {
        dispatch(changeRoutingNumberError(routingNumValidation));
        return;
      }

      dispatch(changeRoutingNumberError(null));
      return;
    }

    dispatch(changeRoutingNumberError(numberValidation));
  }

  function handleRoutingNumberChange(event) {
    const { target } = event;

    dispatch(changeReimbursementRoutingNumber(target.value));
    validateRoutingNumber(target.value, target.validity.valid);
  }

  function validateConfirmBankAccountNumber(confirmAccountNumber) {
    if (!confirmAccountNumber.length) {
      setConfirmAccountNumberError('errors.mustContainNumbersOnly');
      return;
    }

    if (confirmAccountNumber === reimbursementData.AccountNumber) {
      setConfirmAccountNumberError(null);
      return;
    }

    setConfirmAccountNumberError('errors.confirmAccountNumberNoMatch');
  }

  function validateConfirmBankAccountNumberManual(confirmAccountNumber,
    accountNumber) {
    if (confirmAccountNumber === accountNumber) {
      setConfirmAccountNumberError(null);
      return;
    }
    setConfirmAccountNumberError('errors.confirmAccountNumberNoMatch');
  }

  function validateBankAccountNumber(accountNumber, formValidation) {
    const numberValidation = validateNumbersOnly(accountNumber);

    if (formValidation && !numberValidation) {
      setAccountNumberError(null);
      return;
    }
    setAccountNumberError(numberValidation);
  }

  function handleAccountNumberChange(event) {
    const { target } = event;

    dispatch(changeReimbursementAccountNumber(target.value));
    validateBankAccountNumber(target.value, target.validity.valid);

    if (reimbursementData.ConfirmAccountNumber) {
      validateConfirmBankAccountNumberManual(
        reimbursementData.ConfirmAccountNumber, target.value,
      );
    }
  }

  function handleConfirmAccountNumberChange(event) {
    const { target } = event;

    dispatch(changeReimbursementConfirmAccountNumber(target.value));
    validateConfirmBankAccountNumber(target.value);
  }

  function handleEditReimbursementCancel() {
    dispatch(changeRoutingNumberError(null));
    setAccountNumberError(null);
    setConfirmAccountNumberError(null);

    if (onCancel) {
      onCancel();
    }
  }

  return ({
    accountNumberError,
    bankAccountRef,
    confirmAccountNumberError,
    confirmAccountRef,
    handleAccountNumberChange,
    handleAccountTypeChange,
    handleArrowKeyPressed,
    handleConfirmAccountNumberChange,
    handleEditReimbursementAccept,
    handleEditReimbursementCancel,
    handleEditReimbursementSuccess,
    handleRoutingNumberChange,
    isBankFormInvalid,
    reimbursementData,
    reimbursementMethodUpdateSuccess,
    routingNumberError,
    routingNumberRef,
    updatingReimbursementMethod,
  });
};

export { useDirectDepositContent };
