import { refreshApiTokens, sessionExpired, showGenericError } from '../actions';
import app from '../app.json';
import { CLAIMS_API_TOKEN, CUSTOMER_API_TOKEN } from './constants';

const BASE_IP = `${process.env.REACT_APP_API_URL}`;
const BASE_IP_CUSTOMER = `${process.env.REACT_APP_API_CUSTOMER_URL}`;
const CLIENT_NAME = 'Petcloud';

// serializeForUri({ param0: 'value 0', param1: 'value:1' }) ===
// 'param0=value%200&param1=value%3A1'
export function serializeForUri(obj = {}) {
  return Object
    .keys(obj)
    .map((key) => `${encodeURI(key)}=${encodeURI(obj[key])}`)
    .join('&');
}

export function saveData(key, data) {
  return localStorage.setItem(key, data);
}

export function getData(key) {
  return localStorage.getItem(key);
}

export async function getStorageOrDefault(key) {
  try {
    const data = await getData(key);

    return JSON.parse(data);
  } catch (_) {
    return {};
  }
}

export function removeData(key) {
  return localStorage.removeItem(key);
}

export function removeAllData() {
  return localStorage.clear();
}

export async function callRefreshToken() {
  const URL = `${BASE_IP}/token`;
  try {
    const REFRESH_TOKEN = await getData('refresh_token');

    const requestData = {
      body: `grant_type=refresh_token&refresh_token=${REFRESH_TOKEN}`
        + '&AppVersion=5.0.0&OSType=petcloud-web',
      headers: {
        'Content-Type': 'text/plain',
        'X-Client-Name': CLIENT_NAME,
        'X-Client-Version': app.version,
      },
      method: 'POST',
    };
    const res = await fetch(URL, requestData);
    const response = await res.json();

    if (res.status === 400) {
      return { error: response.error, success: false };
    }

    saveData('token', response.access_token);
    saveData('refresh_token', response.refresh_token);

    return { success: true };
  } catch (error) {
    return {
      error,
      success: false,
    };
  }
}

export const IDOR_HEADER_V2 = { 'Api-Version': 2 };
export const API_HEADER_V2 = { 'Api-Version': 'v2' };

async function getApiToken(url = '', isCustomerApi = false, dispatch) {
  const BASE = isCustomerApi ? BASE_IP_CUSTOMER : BASE_IP;
  const fullUrl = `${BASE}/${url}`;
  let tokenKey = 'token';

  if (app.useMultipleScopes) {
    if (fullUrl.includes(`${BASE}/customer`)) {
      tokenKey = CUSTOMER_API_TOKEN;
    } else if (fullUrl.includes(`${BASE}/claims`)) {
      tokenKey = CLAIMS_API_TOKEN;
    } else if (fullUrl.includes('/FeatureFlag')) {
      tokenKey = 'token';
    }
  }

  const token = await getData(tokenKey);

  if (!token && tokenKey !== 'token') {
    dispatch(refreshApiTokens());
  }

  return token;
}

async function call(
  dispatch,
  URL,
  data,
  method,
  returnObject = 'json',
  extraHeaders = {},
  isCustomerApi = false,
  responseJson = true,
) {
  const token = await getApiToken(URL, isCustomerApi, dispatch);
  const headers = {
    'Api-Version': 'v1',
    authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'X-Client-Name': CLIENT_NAME,
    'X-Client-Version': app.version,
    ...extraHeaders,
  };

  try {
    const requestData = {
      body: data && JSON.stringify(data),
      headers,
      method,
    };

    const BASE = isCustomerApi ? BASE_IP_CUSTOMER : BASE_IP;
    const response = await fetch(`${BASE}/${URL}`, requestData);

    const refreshTokenValue = getData('refresh_token');

    if (response.status === 401 && refreshTokenValue) {
      dispatch(sessionExpired());
    } else if (response.status >= 500) {
      const error = await response.json();

      if (dispatch && returnObject === 'json' && !error.detail) {
        dispatch(showGenericError(true));
      }

      return { error, success: false };
    } else if (response.status === 204) {
      return { success: true };
    }

    let returnableData = {};
    if (returnObject === 'blob') {
      const res = await response.blob();
      returnableData = { blob: res };
    } else {
      const res = responseJson ? await response.json()
        : { data: await response.text() };
      returnableData = res;

      if (returnableData instanceof Array) {
        return { data: returnableData, success: true };
      }
    }

    return { success: response.ok, ...returnableData };
  } catch (error) {
    if (dispatch) {
      dispatch(showGenericError(true));
    }
    return { error, success: false };
  }
}

export function get(dispatch, URL, params = {}) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call(dispatch, url, null, 'GET');
}

/* api version 2 */
export function getApiV2(dispatch, URL, params = {}) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call(dispatch, url, null, 'GET', 'json', API_HEADER_V2);
}

export function postApiV2(dispatch, URL, data, returnObject = 'json') {
  return call(dispatch, URL, data, 'POST', returnObject, API_HEADER_V2);
}

export function putApiV2(dispatch, URL, data, formData = false) {
  return call(dispatch, URL, data, 'PUT', formData, API_HEADER_V2);
}

export function delApiV2(dispatch, URL, data) {
  return call(dispatch, URL, data, 'DELETE', null, API_HEADER_V2);
}

export function patchApiV2(
  dispatch,
  URL,
  data,
  isCustomerApi = false,
  responseJson = false,
) {
  return call(
    dispatch,
    URL,
    data,
    'PATCH',
    null,
    API_HEADER_V2,
    isCustomerApi,
    responseJson,
  );
}

export function postApiBlobV2(dispatch, URL, data) {
  return call(dispatch, URL, data, 'POST', 'blob', API_HEADER_V2);
}

/* api IDOR version 2 */
export function getV2(dispatch, URL, params = {}) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call(dispatch, url, null, 'GET', 'json', IDOR_HEADER_V2);
}

export function post(dispatch, URL, data, returnObject = 'json') {
  return call(dispatch, URL, data, 'POST', returnObject);
}

export function postBlob(dispatch, URL, data) {
  return call(dispatch, URL, data, 'POST', 'blob');
}

export function postV2(dispatch, URL, data, returnObject = 'json') {
  return call(dispatch, URL, data, 'POST', returnObject, IDOR_HEADER_V2);
}

/* api IDOR version 2 */
export function postBlobV2(dispatch, URL, data) {
  return call(dispatch, URL, data, 'POST', 'blob', IDOR_HEADER_V2);
}

export function put(dispatch, URL, data, formData = false) {
  return call(dispatch, URL, data, 'PUT', formData);
}

export function del(dispatch, URL, data) {
  return call(dispatch, URL, data, 'DELETE');
}

export function patch(
  dispatch,
  URL,
  data,
  isCustomerApi = true,
  responseJson = false,
) {
  return call(
    dispatch,
    URL,
    data,
    'PATCH',
    null,
    null,
    isCustomerApi,
    responseJson,
  );
}

export async function signIn({ b2cToken, requestB2CBody, requestBody, url }) {
  const URL = `${BASE_IP}${url}`;
  const { version } = app;
  let headers = {
    'Content-Type': 'application/json',
    'X-Client-Name': CLIENT_NAME,
    'X-Client-Version': version,
  };

  const appVersion = `AppVersion=${version}`;
  let body =
    `${requestBody}&grant_type=password&${appVersion}&OSType=petcloud-web`;

  if (requestB2CBody && b2cToken) {
    body = JSON.stringify({
      ...requestB2CBody,
      AppVersion: version,
    });

    headers = {
      ...headers,
      Authorization: `Bearer ${b2cToken}`,
    };
  }

  try {
    const requestData = {
      body,
      headers,
      method: 'POST',
    };

    const response = await fetch(URL, requestData);
    const res = await response.json();

    return {
      ...res,
      success: true,
    };
  } catch (_) {
    return {
      success: false,
    };
  }
}

export async function apiCallWithApiKey(path, params = {}, dispatch) {
  try {
    let url = `${BASE_IP}/${path}`;

    const options = {
      headers: {
        Authorization: 'APIKEY 8f7ecbb6-a62c-44d9-ab88-7b295ba7956c',
        'Content-Type': 'application/json',
        'X-Client-Name': CLIENT_NAME,
        'X-Client-Version': app.version,
        ...params.headers,
      },
    };

    if (params.params) {
      const queryParams = serializeForUri(params);
      url += `?${queryParams}`;
    }

    if (params.method === 'POST' || params.method === 'DELETE') {
      options.method = params.method;
      options.body = JSON.stringify(params.body);
    }

    const response = await fetch(url, options);
    const res = await response.json();

    if (response.status >= 500) {
      if (dispatch) {
        dispatch(showGenericError(true));
      }
      return {
        ...res,
        success: false,
      };
    }

    return {
      ...res,
      success: true,
    };
  } catch (_) {
    if (dispatch) {
      dispatch(showGenericError(true));
    }
    return {
      success: false,
    };
  }
}
