import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useWebviewMultibrand } from '../../hooks';
import './OrcaGetQuoteFrame.css';
import { INSURANCE } from '../navigation/routes.constants';

export const OrcaGetQuoteFrame = () => {
  const history = useHistory();

  const {
    isLoading,
    title,
    url,
  } = useWebviewMultibrand();

  useEffect(() => {
    if (!url) {
      history.replace(INSURANCE);
    }
  }, []);

  return (
    <div className="Orca-get-quote-frame">
      {!isLoading && (
        <iframe
          allowFullScreen
          src={url}
          title={title}
        />
      )}
    </div>
  );
};
