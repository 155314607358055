import React from 'react';
import { useTranslation } from 'react-i18next';
import emptyFolder from '../../assets/inboxDocs/empty-folder.svg';
import { InboxAndDocsTableHeader } from
'./foldersContent/fileListTable/InboxAndDocsTableHeader';
import { TrashHeaderContainer } from
'./foldersContent/fileListTable/TrashHeaderContainer';
import './InboxAndDocsEmptyScreen.css';
import { InboxAndDocsTable } from
'./foldersContent/fileListTable/InboxAndDocsTable';
import TouchableDiv from '../common/TouchableDiv';
import { INBOX_DOCS, INBOX_DOCS_PRIVATE_FOLDERS } from '../../constants';

const PRIVATE_FOLDER = [...INBOX_DOCS_PRIVATE_FOLDERS, INBOX_DOCS.trash];

const InboxAndDocsEmptyScreen = ({
  folderId = -1,
  handleAddFiles = () => { },
  isTrashFolder = false,
}) => {
  const { t } = useTranslation('inboxAndDocs');

  const isThrashCSS = isTrashFolder && 'Align-items-flex-end';
  const headerEmptyFolderCSS =
    `Inbox-and-docs-empty-top-container Flex Flex-column ${isThrashCSS}`;
  const isPrivateFolder = PRIVATE_FOLDER.includes(folderId);

  function renderHeadersEmptyFolder() {
    return (
      <div className={headerEmptyFolderCSS}>
        {!isTrashFolder
          ? (
            <InboxAndDocsTableHeader
              isEmptyFolder
              petSelectedTitle={t('allPets')}
            />
          )
          : <TrashHeaderContainer isDisabled />}

        <InboxAndDocsTable
          currentFolderId={folderId}
          isEmptyTrash={isTrashFolder}
        />
      </div>
    );
  }

  return (
    <>
      {renderHeadersEmptyFolder()}

      <div className="Inbox-and-docs-no-content Flex Flex-column">
        <img alt="" height={70} src={emptyFolder} width={70} />

        <span className="Inbox-and-docs-empty-title">{t('emptyFolder')}</span>

        {!isPrivateFolder
          && (
            <div className="Inbox-and-docs-empty-subtitle Flex-row">
              {t('dropFiles')}

              <TouchableDiv
                className="Browse-text"
                id="browseEmptyBtn"
                onClick={handleAddFiles}
              >
                <span
                  className="Inbox-and-docs-browse No-clicked"
                  id="browseEmptyBtn"
                >
                  {t('browse')}
                </span>
              </TouchableDiv>

              {t('toUpload')}
            </div>
          )}
      </div>
    </>
  );
};

export { InboxAndDocsEmptyScreen };
