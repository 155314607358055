import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  acceptAddressUpdateLoading: false,
  address1: '',
  address2: '',
  addressUpdateError: null,
  areNamesUpdated: false,
  arePhonesUpdated: false,
  cancelingAddressUpdate: false,
  city: '',
  endorsementData: null,
  endorsementMessageModalVisible: false,
  endorsementSuccessModalVisible: false,
  errorAddress: '',
  errorUpdateInsuranceInformation: null,
  firstName: '',
  FirstNameHasNumbers: false,
  isAddress1Valid: true,
  isAddress2Valid: true,
  isCityValid: true,
  isConfirmAddressChangeModalVisible: false,
  isFirstNameValid: true,
  isLastNameValid: true,
  isMultiplePetsModal: false,
  isNamesBeingUpdated: false,
  isPhonesBeingUpdated: false,
  isPrimaryPhoneValid: true,
  isSecondaryPhoneValid: true,
  isZipCodeValid: true,
  lastName: '',
  LastNameHasNumbers: false,
  loadingUpdateInsuranceInformation: false,
  namesUpdateError: null,
  pets: [],
  petsLoading: false,
  phonesUpdateError: null,
  pictureEditing: {
    error: false,
    loading: false,
    message: '',
    success: false,
  },
  primaryPhone: '',
  PrimaryPhoneHasLetters: false,
  profile: {},
  profileLoading: false,
  secondaryPhone: '',
  SecondaryPhoneHasLetters: false,
  state: null,
  states: [],
  userInfo: {},
  zipCodeHasLetters: false,
};
const NAME_REGEX = /^[a-z ,.'-]+$/i;
const ONLY_NUMBERS_REGEX = /^\d+$/;
const PHONE_REGEX = /^[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export default handleActions({
  ACCEPT_ADDRESS_UPDATE: (state, { payload }) => ({
    ...state,
    acceptAddressUpdateLoading: payload,
  }),
  ADDRESS_UPDATED: (state, { payload }) => ({
    ...state,
    address1: payload.Line1,
    address2: payload.Line2,
    addressUpdateError: null,
    isAddress1Valid: true,
    isAddress2Valid: true,
    isCityValid: true,
    isZipCodeValid: true,
    userInfo: {
      ...state.userInfo,
      Address: {
        ...state.userInfo.Address,
        City: state.city,
        Line1: payload.Line1,
        Line2: payload.Line2,
        StateId: state.state,
        ZipCode: state.zipCode,
      },
    },
  }),
  ADDRESS1_CHANGED: (state, { payload }) => ({
    ...state,
    address1: payload.value,
    errorAddress: payload.errorAddress,
    isAddress1Valid: payload.isValid,
  }),
  ADDRESS2_CHANGED: (state, { payload }) => ({
    ...state,
    address2: payload,
  }),
  AVATAR_PICTURE_REMOVED: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      error: false,
      loading: false,
      success: true,
    },
  }),
  CANCEL_ADDRESS_UPDATE: (state) => ({
    ...state,
    acceptAddressUpdateLoading: false,
    cancelingAddressUpdate: true,
  }),
  CHANGE_PROFILE_PICTURE: (state) => ({
    ...state,
    pictureEditing: {
      ...INITIAL_STATE.pictureEditing,
      loading: true,
    },
  }),
  CHANGE_PROFILE_PICTURE_FAILED: (state, { payload }) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      loading: false,
      message: payload,
    },
  }),
  CHANGE_PROFILE_PICTURE_RESTART: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      loading: false,
      message: '',
      success: false,
    },
  }),
  CHANGE_PROFILE_PICTURE_SUCCESS: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      loading: false,
      success: true,
    },
  }),
  CITY_CHANGED: (state, { payload }) => ({
    ...state,
    city: payload,
    isCityValid: payload.length,
  }),
  CLOSE_ENDORSEMENT_SUCCESS_MODAL: (state) => ({
    ...state,
    endorsementSuccessModalVisible: false,
  }),
  CONFIRM_ADDRESS_CHANGE_MODAL_CHANGED: (state, { payload }) => ({
    ...state,
    isConfirmAddressChangeModalVisible: payload,
  }),
  COVER_PICTURE_REMOVED: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      error: false,
      loading: false,
      success: true,
    },
  }),
  DISPLAY_ENDORSEMENT_MESSAGE_MODAL: (state, { payload }) => ({
    ...state,
    endorsementMessageModalVisible: payload,
  }),
  DISPLAY_ENDORSEMENT_SUCCESS_MODAL: (state) => ({
    ...state,
    endorsementSuccessModalVisible: true,
  }),
  DO_LOGOUT: () => ({ ...INITIAL_STATE }),
  ENDORSEMENT_LOADED: (state, action) => ({
    ...state,
    endorsementData: action.payload.data,
    isMultiplePetsModal: action.payload.multiplePets,
  }),
  FIRST_NAME_CHANGED: (state, { payload }) => ({
    ...state,
    areNamesUpdated: false,
    firstName: payload,
    FirstNameHasNumbers: /\d/.test(payload),
    isFirstNameValid: NAME_REGEX.test(payload),
  }),
  FIRST_NAME_RESET: (state) => ({
    ...state,
    firstName: state.userInfo.FirstName,
    FirstNameHasNumbers: false,
    isFirstNameValid: true,
  }),
  LAST_NAME_CHANGED: (state, { payload }) => ({
    ...state,
    areNamesUpdated: false,
    isLastNameValid: NAME_REGEX.test(payload),
    lastName: payload,
    LastNameHasNumbers: /\d/.test(payload),
  }),
  LAST_NAME_RESET: (state) => ({
    ...state,
    isLastNameValid: true,
    lastName: state.userInfo.LastName,
    LastNameHasNumbers: false,
  }),
  NAMES_UPDATED: (state, { payload }) => ({
    ...state,
    areNamesUpdated: true,
    firstName: payload.FirstName,
    isFirstNameValid: true,
    isLastNameValid: true,
    isNamesBeingUpdated: false,
    lastName: payload.LastName,
    namesUpdateError: null,
    userInfo: {
      ...state.userInfo,
      FirstName: payload.FirstName,
      LastName: payload.LastName,
    },
  }),
  PETS_LOAD_ATTEMPT: (state) => ({
    ...state,
    petsLoading: true,
  }),
  PETS_LOADED: (state, { payload }) => ({ ...state, pets: [...payload] }),
  PETS_LOADED_FINISHED: (state) => ({
    ...state,
    petsLoading: false,
  }),
  PHONES_UPDATED: (state) => ({
    ...state,
    arePhonesUpdated: true,
    isPhonesBeingUpdated: false,
    phonesUpdateError: null,
    userInfo: {
      ...state.userInfo,
      OtherPhoneNumber: state.secondaryPhone,
      PhoneNumber: state.primaryPhone,
    },
  }),
  PRIMARY_PHONE_CHANGED: (state, { payload }) => ({
    ...state,
    arePhonesUpdated: false,
    isPrimaryPhoneValid: payload
      && PHONE_REGEX.test(payload),
    primaryPhone: payload,
    PrimaryPhoneHasLetters: !ONLY_NUMBERS_REGEX.test(payload),
  }),
  REMOVE_AVATAR_PICTURE: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      error: false,
      loading: true,
      success: false,
    },
  }),
  REMOVE_AVATAR_PICTURE_FAILED: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      error: true,
      loading: false,
      success: false,
    },
  }),
  REMOVE_COVER_PICTURE: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      error: false,
      loading: true,
      success: false,
    },
  }),
  REMOVE_COVER_PICTURE_FAILED: (state) => ({
    ...state,
    pictureEditing: {
      ...state.pictureEditing,
      error: true,
      loading: false,
      success: false,
    },
  }),
  RESET_ADDRESS: (state) => ({
    ...state,
    address1: state.userInfo.Address.Line1,
    address2: state.userInfo.Address.Line2,
    city: state.userInfo.Address.City,
    state: state.userInfo.Address.StateId,
    zipCode: state.userInfo.Address.ZipCode,
  }),
  RESET_ENDORSEMENT_DATA: (state) => ({
    ...state,
    cancelingAddressUpdate: false,
    endorsementData: null,
  }),
  RESET_INFORMATION: (state) => ({
    ...INITIAL_STATE,
    address1: state.userInfo.Address.Line1,
    address2: state.userInfo.Address.Line2 || '',
    city: state.userInfo.Address.City,
    firstName: state.userInfo.FirstName,
    lastName: state.userInfo.LastName,
    primaryPhone: state.userInfo.PhoneNumber,
    secondaryPhone: state.userInfo.OtherPhoneNumber || '',
    state: state.userInfo.Address.StateId,
    states: state.states,
    userInfo: state.userInfo,
    zipCode: state.userInfo.Address.ZipCode,
  }),
  RESET_NAME: (state) => ({
    ...state,
    firstName: state.userInfo.FirstName,
    lastName: state.userInfo.LastName,
  }),
  RESET_PHONE: (state) => ({
    ...state,
    primaryPhone: state.userInfo.PhoneNumber || '',
    secondaryPhone: state.userInfo.OtherPhoneNumber || '',
  }),
  SAVE_USER_INFO: (state, { payload }) => ({
    ...state,
    address1: payload.Address.Line1,
    address2: payload.Address.Line2 || '',
    city: payload.Address.City,
    firstName: payload.FirstName,
    lastName: payload.LastName,
    primaryPhone: payload.PhoneNumber,
    secondaryPhone: payload.OtherPhoneNumber || '',
    state: payload.Address.StateId,
    userInfo: payload,
    zipCode: payload.Address.ZipCode,
  }),
  SAVE_USER_INFO_ADDRESS: (state, { payload }) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      Address: {
        ...state.userInfo.Address,
        City: payload.city,
        StateId: payload.state,
        ZipCode: payload.zipCode,
      },
    },
  }),
  SECONDARY_PHONE_CHANGED: (state, { payload }) => ({
    ...state,
    arePhonesUpdated: false,
    isSecondaryPhoneValid: payload
      && PHONE_REGEX.test(payload),
    secondaryPhone: payload,
    SecondaryPhoneHasLetters: !ONLY_NUMBERS_REGEX.test(payload),
  }),
  SECONDARY_PHONE_RESET: (state) => ({
    ...state,
    isSecondaryPhoneValid: true,
    secondaryPhone: '',
  }),
  SOCIAL_PROFILE_LOAD_ATTEMPT: (state) => ({
    ...state,
    profileLoading: true,
  }),
  SOCIAL_PROFILE_LOADED: (state, { payload }) => ({
    ...state,
    profile: payload,
  }),
  SOCIAL_PROFILE_LOADED_FINISHED: (state) => ({
    ...state,
    profileLoading: false,
  }),
  STATE_CHANGED: (state, { payload }) => ({
    ...state,
    addressUpdateError: null,
    state: payload,
  }),
  STORE_STATES: (state, { payload }) => ({
    ...state,
    states: payload,
  }),
  UPDATE_INSURANCE_INFORMATION: (state) => ({
    ...state,
    loadingUpdateInsuranceInformation: true,
  }),
  UPDATE_INSURANCE_INFORMATION_FAILED: (state) => ({
    ...state,
    errorUpdateInsuranceInformation: true,
    loadingUpdateInsuranceInformation: false,
  }),
  UPDATE_INSURANCE_INFORMATION_SUCCESS: (state) => ({
    ...state,
    errorUpdateInsuranceInformation: false,
    loadingUpdateInsuranceInformation: false,
  }),
  UPDATE_NAMES: (state) => ({
    ...state,
    areNamesUpdated: false,
    isNamesBeingUpdated: true,
  }),
  UPDATE_PHONES: (state) => ({
    ...state,
    arePhonesUpdated: false,
    isPhonesBeingUpdated: true,
  }),
  UPDATE_PHONES_ERROR: (state) => ({
    ...state,
    arePhonesUpdated: false,
    isPhonesBeingUpdated: false,
  }),
  ZIPCODE_CHANGED: (state, { payload }) => ({
    ...state,
    isZipCodeValid: payload.length >= 5
      && payload.length <= 9
      && ONLY_NUMBERS_REGEX.test(payload)
      && ZIP_CODE_REGEX.test(payload),
    zipCode: payload,
    zipCodeHasLetters: ONLY_NUMBERS_REGEX.test(payload),
  }),
}, INITIAL_STATE);
