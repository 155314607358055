import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  claimAdditionalStepsHandled,
  claimbotSelectedPet,
  claimbotSelectedValue,
  claimGetPolicyByDate,
  claimStepsViewed,
  clearClaimSteps,
  fileClaimsRestart,
  loadAdditionalClaimbotSteps,
  loadClaimSteps,
  submitClaim,
} from '../../../../actions';

import {
  ACCOUNT_NUMBER_PROP_NAME,
  ACCOUNT_TYPE_PROP_NAME,
  ALL_ATTACHMENT_STEPS,
  ORCA_POLICY_ID_PROP_NAME,
  POLICY_NUMBER_PROP_NAME,
  REIMBURSEMENT_METHOD_ACCOUNT_TYPE,
  REIMBURSEMENT_METHOD_TYPE_KEY_NAME,
  REIMBURSEMENT_METHOD_TYPE,
  ROUTING_NUMBER_PROP_NAME,
  SIGNATURE_TYPE,
  STEP_KEY_NAME,
  STEP_TYPES_TO_SKIP_FROM_HISTORY,
  STEPS_TO_SKIP_FROM_HISTORY,
} from '../claimbot.constants';
import {
  formatDateClaimSummary,
  formatDateSubmitClaim,
  getDateOrToday,
} from '../claimbot.utils';
import {
  currencyFormat,
  delay,
  isRoutingNumberValid,
  testNumberWithDecimal,
  toBase64,
  validateNumbersOnly,
  getBrandNameByChannelId,
} from '../../../../services/utils';
import { CLAIMS } from '../../../navigation/routes.constants';
import { ALLOWED_EXT, MAX_SIZE_MB } from '../../../../constants';
import { MARKETING_CHANNEL_ID } from '../../../../theme/theme.constants';

let currentTogglerVisibleFn = () => { };

const useFileClaimbotScreen = () => {
  const store = useSelector(({
    claimsGeneric,
  }) => ({ claimsGeneric }), shallowEqual);
  const { claimsGeneric } = store;
  const { t } = useTranslation('fileClaimbot');
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(null);
  const [currentStepValid, setCurrentStepValid] = useState(false);
  const [stepsHistory, setStepsHistory] = useState([]);
  const [agentText, setAgentText] = useState('');
  const [goBackDisabled, setGoBackDisabled] = useState(true);
  const [goForwardisabled, setGoForwardDisabled] = useState(true);
  const [finished, setFinished] = useState(false);

  const [userSelection, setUserSelection] = useState(null);
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectedDate, setSelectedDate] = useState('');
  const [claimTypeSelected, setClaimTypeSelected] = useState(null);
  const [textInputValue, setTextInputValue] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [amountInput, setAmountInput] = useState('');
  const [optionSelected, setOptionSelected] = useState(null);
  const [signature, setSignature] = useState(null);
  // TODO: variable required for mix-panel events
  // eslint-disable-next-line no-unused-vars
  const [datesCaptured, setDatesCaptured] = useState([]);
  const [twoOptions, setTwoOptions] = useState({});
  const [multipleOptions, setMultipleOptions] = useState({});
  const [signatureTypeSelected, setSignatureTypeSelected] =
    useState(SIGNATURE_TYPE.TYPING);
  const [signatureText, setSignatureText] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');

  const [unknowStepVisible, setUnknowStepVisible] = useState(false);
  const [finishButtonVisible, setFinishButtonVisible] = useState(false);
  const [petSelectorVisible, setPetSelectorVisible] = useState(false);
  const [dateInputVisible, setDateInputVisible] = useState(false);
  const [claimSelectorVisible, setClaimSelectorVisible] = useState(false);
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [textInputSingleLineVisible,
    setTextInputSingleLineVisible] = useState(false);
  const [attSelectorVisible, setAttSelectorVisible] = useState(false);
  const [amountInputVisible, setAmountInputVisible] = useState(false);
  const [twoOptSelectVisible, setTwoOptSelectVisible] = useState(false);
  const [multiOptSelectVisible, setMultiOptSelectVisible] = useState(false);
  const [claimSummaryVisible, setClaimSummaryVisible] = useState(false);
  const [signAndSubmitVisible, setSignAndSubmitVisible] = useState(false);
  const [claimSubmittedVisible, setClaimSubmittedVisible] = useState(false);
  const [routingNumberVisible, setRoutingNumberVisible] = useState(false);
  const [accountNumberVisible, setAccountNumberVisible] = useState(false);
  const [policySelectorVisible, setPolicySelectorVisible] = useState(false);
  const [policySelected, setPolicySelected] = useState(null);

  const agentImage = claimsGeneric.agentInformation?.PictureUrl;
  const numberOfSteps = 10;
  const currentStepNumber = useMemo(() => {
    let stepNum = 1;

    if (!currentStep || !stepsHistory || !stepsHistory.length) {
      return stepNum;
    }

    if (stepsHistory.find((stp) => stp.KeyName
      === STEP_KEY_NAME.submit_claim)
      || currentStep.KeyName === STEP_KEY_NAME.submit_claim) {
      stepNum = 10;
    } else if (stepsHistory.find((stp) => stp.KeyName
      === STEP_KEY_NAME.show_summary)
      || currentStep.KeyName === STEP_KEY_NAME.show_summary) {
      stepNum = 9;
    } else if (stepsHistory.find((stp) => ALL_ATTACHMENT_STEPS
      .includes(stp.KeyName))
      || ALL_ATTACHMENT_STEPS.includes(currentStep.KeyName)) {
      stepNum = 6;
    }

    return stepNum;
  }, [currentStep, stepsHistory]);

  const backButtonLabel = t('back');
  const nextButtonLabel = useMemo(() => {
    if (finished) {
      return t('finish');
    }

    // TODO: claim summary step -> submit label

    // TODO: error case -> retry label

    return t('next');
  }, [finished, t]);

  const pets = useMemo(() => {
    const { claimsGeneric: { petsInfo } } = store;

    if (!petsInfo) {
      return [];
    }

    return petsInfo;
  }, [store.claimsGeneric.petsInfo]);

  const claimTypes = useMemo(() => {
    const { claimsGeneric: { petsInfo } } = store;

    if (!selectedPet || !petsInfo) {
      return [{ id: 1, label: 'opt1' }, { id: 2, label: 'opt2' }];
    }

    const petInformation = petsInfo
      .find((item) => item.Id === selectedPet.Id);

    // show claim types using the selected policy or default
    const petInfoByPolicy = policySelected ?? petInformation.PetValidDates[0];

    const claimTypesByPet = petInfoByPolicy.ClaimTypes
      .map((item) => ({
        id: item.ClaimTypeId,
        label: item.ClaimTypeDescription,
      }));

    return claimTypesByPet;
  }, [
    store.claimsGeneric.selectedPet,
    store.claimsGeneric.petsInfo,
    policySelected,
  ]);

  const policyBrandName = useMemo(() => {
    const { claimsGeneric: { petsInfo } } = store;

    const petInformation = petsInfo
      ?.find((item) => item?.Id === selectedPet?.Id);

    const petInfoByPolicy = policySelected ?? petInformation?.PetValidDates[0];
    return getBrandNameByChannelId(
      petInfoByPolicy?.PolicyMarketingChannelId,
    );
  }, [
    store.claimsGeneric.selectedPet,
    store.claimsGeneric.petsInfo,
    policySelected]);

  const selectedPetPolicies = useMemo(() => {
    const { claimsGeneric: { petsInfo } } = store;

    if (!petsInfo || !selectedPet) {
      return null;
    }

    return selectedPet?.PetValidDates || [];
  }, [store.claimsGeneric.petsInfo, store.claimsGeneric.selectedPet]);

  const reimbursementMethodUser = useMemo(() => {
    if (!policySelected || !policySelected.EftInfo) {
      return '';
    }

    let reimbursementMethod = '';
    const eftInfo = policySelected.EftInfo;
    const prefReimbMethod = eftInfo.PreferredReimbursementMethod;
    const baseTransEntry = 'reimbursementInformation'
      + '.reimbursementMethodsAgentMessage';

    if (prefReimbMethod === REIMBURSEMENT_METHOD_TYPE.ACH
      && eftInfo.AccountNumber) {
      reimbursementMethod = t(`${baseTransEntry}.ach`)
        .replace('{account}', eftInfo.AccountNumber.slice(-4));
    } else if (prefReimbMethod === REIMBURSEMENT_METHOD_TYPE.CHECK) {
      reimbursementMethod = t(`${baseTransEntry}.check`);
    } else {
      reimbursementMethod = t(`${baseTransEntry}.none`);
    }

    return reimbursementMethod;
  }, [policySelected, t]);

  const claimSummaryInfo = useMemo(() => {
    if (!claimsGeneric.userResponses) {
      return [];
    }

    return Object
      .values(claimsGeneric.userResponses)
      .filter((item) => !!item.displayName);
  }, [claimsGeneric]);

  const synteticSelectPolicyStep = useMemo(() => ({
    ClaimType: null,
    KeyName: STEP_KEY_NAME.syntetic_get_select_policy,
    MessageText: t('synteticPolicySelectorDialog'),
    NextTrueKeyName: STEP_KEY_NAME.ok_select_pet,
  }), [t]);

  const getDateWithFormat = (date) => {
    if (!date) {
      return '';
    }

    return date.split('T')[0];
  };

  const validateOnlyFigoPolicies = useCallback((petValidDates) => {
    if (!petValidDates) {
      return false;
    }

    return petValidDates.every((pol) => pol.PolicyMarketingChannelId
      === MARKETING_CHANNEL_ID.figo);
  }, []);

  const onlyFigoPolicies = useMemo(() => {
    if (selectedPet && selectedPet.PetValidDates) {
      return validateOnlyFigoPolicies(selectedPet.PetValidDates);
    }

    return false;
  }, [selectedPet, validateOnlyFigoPolicies]);

  const coverageDateRange = useMemo(() => {
    if (!selectedPet) {
      return {
        maxCoverageDate: null,
        minCoverageDate: null,
      };
    }

    const petValidDates = selectedPet.PetValidDates;

    if (petValidDates.length === 1) {
      const policy = petValidDates[0];
      return {
        maxCoverageDate: getDateOrToday(
          getDateWithFormat(policy.ExpirationDate),
        ),
        minCoverageDate: getDateWithFormat(policy.EffectiveDate),
      };
    }

    const [minCoverageDate] = selectedPet.PetValidDates
      .map((pol) => getDateWithFormat(pol.EffectiveDate))
      .sort((a, b) => {
        const date1 = new Date(b);
        const date2 = new Date(a);

        return date1 < date2 ? 1 : -1;
      });

    const [maxCoverageDate] = selectedPet.PetValidDates
      .map((pol) => getDateWithFormat(pol.ExpirationDate))
      .sort((a, b) => {
        const date1 = new Date(b);
        const date2 = new Date(a);

        return date1 > date2 ? 1 : -1;
      });

    return {
      maxCoverageDate: getDateOrToday(maxCoverageDate),
      minCoverageDate,
    };
  }, [onlyFigoPolicies, selectedPet]);

  const injectPolicySelectorStep = (logStep = false) => {
    if (logStep) {
      const stepWithSelection = {
        ...currentStep,
        userSelection,
      };

      setStepsHistory((prev) => [...prev, stepWithSelection]);
    }

    synteticSelectPolicyStep.NextTrueKeyName =
      currentStep.NextTrueKeyName;
    synteticSelectPolicyStep.NextFalseKeyName =
      currentStep.NextFalseKeyName;

    setCurrentStep(synteticSelectPolicyStep);
  };

  useEffect(() => () => {
    dispatch(clearClaimSteps());
    dispatch(fileClaimsRestart());
  }, []);

  useEffect(() => {
    // load Claims types after refresh page
    if (claimsGeneric.claimSteps) {
      const firsStep = claimsGeneric.claimSteps[0];
      setCurrentStep(firsStep);
      dispatch(claimStepsViewed());
      return;
    }

    dispatch(loadClaimSteps());
  }, [claimsGeneric.claimSteps]);

  const onPreviousStepClick = () => {
    const previousStep = stepsHistory.pop();
    setCurrentStep(previousStep);
    currentTogglerVisibleFn(false);
  };

  /**
   * Stores the value captured by the user, in the backend dynamic
   * property name.
   * @param {*} customKeyName custom keyname to store and use on claim submiting
   * @param {*} displayValue value to display to the user
   * @param {*} value value captured from the user
   */
  const storeSelectedValue = ({
    customDisplayName = '',
    customKeyName = '',
    displayValue = '',
    hideDisplayName = false,
    value,
  }) => {
    const keyName = currentStep?.Input?.StoredField;
    const displayName = currentStep?.Input?.SummaryField;

    if (keyName || customKeyName || displayName) {
      dispatch(claimbotSelectedValue({
        displayName: hideDisplayName ? '' : (customDisplayName || displayName),
        displayValue,
        keyName: customKeyName || keyName,
        value,
      }));
    }
  };

  const selectPolicy = useCallback((policy) => {
    setPolicySelected(policy);

    const orcaPolicyId = policy.PolicyId;
    const petPolicyNumber = policy.PolicyNumber;

    storeSelectedValue({
      customKeyName: ORCA_POLICY_ID_PROP_NAME,
      displayValue: orcaPolicyId,
      hideDisplayName: true,
      value: orcaPolicyId,
    });

    storeSelectedValue({
      customKeyName: POLICY_NUMBER_PROP_NAME,
      displayValue: petPolicyNumber,
      hideDisplayName: true,
      value: petPolicyNumber,
    });
  }, []);

  /**
   * Goes to the nex step fo the claim type specified (first as default).
   * @param {Object} params
   * @param {number} params.claimTypeId claim type steps to follow
   * @param {number} params.marketChannelId marketing channel claimbot
   */
  const goToNextStepByClaimType = (claimTypeId) => {
    const nextStep = claimsGeneric.claimSteps
      .find((stp) => stp.ClaimTypeId === claimTypeId);

    setCurrentStep(nextStep);
  };

  useEffect(() => {
    const { additionalStepsHandled } = store.claimsGeneric;

    if (!additionalStepsHandled && claimTypeSelected) {
      dispatch(claimAdditionalStepsHandled());

      goToNextStepByClaimType(claimTypeSelected.id);
    }
  }, [store.claimsGeneric.additionalStepsHandled, claimTypeSelected]);

  const getNextStep = (validStep = false) => {
    const nextStepName = validStep
      ? currentStep.NextTrueKeyName : currentStep.NextFalseKeyName;

    const nextStep = claimsGeneric.claimSteps
      .find((stp) => stp.KeyName === nextStepName);

    return nextStep;
  };

  const storeStepInHistory = ({ step, stepWithSelection }) => {
    if (step) {
      setCurrentStep(step);
    }

    const skipByInputType = currentStep.Input && STEP_TYPES_TO_SKIP_FROM_HISTORY
      .includes(currentStep.Input.TypeId);

    const skipByStep = STEPS_TO_SKIP_FROM_HISTORY
      .includes(currentStep.KeyName);

    if (!skipByInputType && !skipByStep) {
      setStepsHistory((prev) => [...prev, stepWithSelection]);
    }
  };

  /**
   * Goes to the next step (valid or invalid)
   * @param validStep determinates if the next step is successfull or failure
   */
  const goToNextStep = (validStep = false) => {
    const stepWithSelection = {
      ...currentStep,
      userSelection,
    };

    // special case, go to next step by claim type.
    if (currentStep.KeyName === STEP_KEY_NAME.select_claim_type) {
      setStepsHistory((prev) => [...prev, stepWithSelection]);
      dispatch(loadAdditionalClaimbotSteps({
        claimTypeId: claimTypeSelected.id,
        keyName: currentStep.KeyName,
        petId: selectedPet.Id,
        policyNumber: policySelected.PolicyNumber,
      }));
      return;
    }

    // only Figo scenario
    if (currentStep.KeyName === STEP_KEY_NAME.get_illness_date) {
      if (onlyFigoPolicies) {
        // get policy by selected date from backend
        dispatch(claimGetPolicyByDate({
          date: selectedDate,
          petId: selectedPet.Id,
        }));
        return;
      }

      injectPolicySelectorStep(true);
      return;
    }

    // only non Figo scenario
    if (!onlyFigoPolicies
      && currentStep.KeyName === STEP_KEY_NAME.get_select_pet
      && selectedPet && selectedPet.PetValidDates.length > 1) {
      // gets the policy by user selection
      injectPolicySelectorStep(true);
      return;
    }

    const nextStep = getNextStep(validStep);
    storeStepInHistory({
      step: nextStep,
      stepWithSelection,
    });
  };

  const onNextStepClick = () => {
    if (finished) {
      history.push(CLAIMS);
    }

    if (!goForwardisabled) {
      currentTogglerVisibleFn(false);
      goToNextStep(currentStepValid);
    }
  };

  const userHasPetsWithPolicyValidation = () => {
    const { petsInfo } = claimsGeneric;

    return petsInfo && petsInfo.length > 0;
  };

  const userHasMoreThanOnePet = () => {
    const { petsInfo } = claimsGeneric;

    return petsInfo && petsInfo.length > 1;
  };

  const showFinishButton = () => {
    setGoBackDisabled(true);
    setFinished(true);
    setFinishButtonVisible(true);
    currentTogglerVisibleFn = setFinishButtonVisible;
  };

  const showClaimSelector = () => {
    setClaimSelectorVisible((prev) => !prev);
    currentTogglerVisibleFn = setClaimSelectorVisible;
  };

  const showPetSelector = () => {
    setPetSelectorVisible(true);
    currentTogglerVisibleFn = setPetSelectorVisible;
  };

  const showPolicySelector = useCallback(() => {
    setPolicySelectorVisible(true);
    currentTogglerVisibleFn = setPolicySelectorVisible;
  }, []);

  const showClaimAmountInput = () => {
    setAmountInputVisible((prev) => !prev);
    currentTogglerVisibleFn = setAmountInputVisible;
  };

  const showSignAndSubmit = () => {
    setSignAndSubmitVisible((prev) => !prev);
    currentTogglerVisibleFn = setSignAndSubmitVisible;
  };

  /**
   * Stores already existing reimbursment information from the user.
   */
  const storeAccountInformationFixed = () => {
    const eftInfo = policySelected.EftInfo;

    const routNumb = eftInfo.RoutingNumber;
    storeSelectedValue({
      customKeyName: ROUTING_NUMBER_PROP_NAME,
      displayValue: routNumb,
      hideDisplayName: true,
      value: routNumb,
    });

    const accNum = eftInfo.AccountNumber;
    storeSelectedValue({
      customKeyName: ACCOUNT_NUMBER_PROP_NAME,
      displayValue: accNum,
      hideDisplayName: true,
      value: accNum,
    });

    const isCheck = eftInfo.Type
      === REIMBURSEMENT_METHOD_TYPE.CHECK;
    const paymentType = isCheck ? 'CHECK' : 'ACH';
    const paymentTypeDisplayValue = isCheck
      ? t('reimbursementInformation.reimbursementMethods.check')
      : t('reimbursementInformation.reimbursementMethods.bankAccount');
    storeSelectedValue({
      customDisplayName: t('reimbursementInformation.paymentMethod'),
      customKeyName: REIMBURSEMENT_METHOD_TYPE_KEY_NAME,
      displayValue: paymentTypeDisplayValue,
      hideDisplayName: false,
      value: paymentType,
    });

    const accountType = eftInfo
      .AccountType === REIMBURSEMENT_METHOD_ACCOUNT_TYPE.Checking
      ? 'Checking' : 'Savings';
    storeSelectedValue({
      customKeyName: ACCOUNT_TYPE_PROP_NAME,
      displayValue: accountType,
      hideDisplayName: true,
      value: accountType,
    });
  };

  const selectPet = (pet) => {
    storeSelectedValue({
      displayValue: pet.Name,
      value: pet.Id,
    });

    setSelectedPet(pet);

    // automatic policy selection
    if (pet.PetValidDates.length === 1) {
      selectPolicy(pet.PetValidDates[0]);
    }

    dispatch(claimbotSelectedPet(pet));
  };

  const onPetItemPress = (pet) => () => {
    setCurrentStepValid(true);
    setGoForwardDisabled(false);
    setUserSelection(pet);
    selectPet(pet);

    // automatic policy selection
    if (pet.PetValidDates.length === 1) {
      selectPolicy(pet.PetValidDates[0]);
    }
  };

  const onPolicyItemPress = (policy) => () => {
    let validStep = true;
    // special case: single pet (no pet selector shown) and multiple policies
    if (currentStep.KeyName === STEP_KEY_NAME.syntetic_get_select_policy
      && store.claimsGeneric.petsInfo.length === 1
      && selectedPet.PetValidDates.length > 1) {
      validStep = false;
    }

    setCurrentStepValid(validStep);
    setGoForwardDisabled(false);
    setUserSelection(policy);
    selectPolicy(policy);
  };

  const showDateInput = () => {
    setDateInputVisible(true);
    currentTogglerVisibleFn = setDateInputVisible;
  };

  const showTextInput = () => {
    setTextInputVisible((prev) => !prev);
    currentTogglerVisibleFn = setTextInputVisible;
  };

  const showTextInputSingleLine = () => {
    setTextInputSingleLineVisible((prev) => !prev);
    currentTogglerVisibleFn = setTextInputSingleLineVisible;
  };

  const showAttSelector = () => {
    setAttSelectorVisible((prev) => !prev);
    currentTogglerVisibleFn = setAttSelectorVisible;
  };

  const showMultiOptSelect = (options) => {
    const optionsWithFormat = options.map((opt) => ({
      id: opt.Id,
      label: opt.Title,
    }));

    if (optionsWithFormat && optionsWithFormat.length === 2) {
      setTwoOptSelectVisible((prev) => !prev);
      currentTogglerVisibleFn = setTwoOptSelectVisible;
      setTwoOptions({
        leftOption: optionsWithFormat[1],
        rightOption: optionsWithFormat[0],
      });
    } else {
      setMultiOptSelectVisible((prev) => !prev);
      currentTogglerVisibleFn = setMultiOptSelectVisible;
      setMultipleOptions(optionsWithFormat);
    }
  };

  const showClaimSummary = () => {
    setClaimSummaryVisible((prev) => !prev);
    currentTogglerVisibleFn = setClaimSummaryVisible;
  };

  const showRoutingNumber = () => {
    setRoutingNumberVisible((prev) => !prev);
    currentTogglerVisibleFn = setRoutingNumberVisible;
  };

  const showAccountNumber = () => {
    setAccountNumberVisible((prev) => !prev);
    currentTogglerVisibleFn = setAccountNumberVisible;
  };

  const onSelectedValidDate = ({ date, inRange }) => {
    setCurrentStepValid(inRange);
    setGoForwardDisabled(!inRange);

    setUserSelection(date);
    setSelectedDate(date);

    const dateValue = formatDateSubmitClaim(date);

    setDatesCaptured((prev) => [...prev, {
      key: currentStep.Input.SummaryField,
      value: date,
    }]);

    storeSelectedValue({
      displayValue: formatDateClaimSummary(date),
      value: dateValue,
    });
  };

  const onSelectClaimType = (claimType) => {
    setCurrentStepValid(true);
    setGoForwardDisabled(false);
    setUserSelection(claimType);

    storeSelectedValue({
      displayValue: claimType.label,
      value: claimType.id,
    });

    setClaimTypeSelected(claimType);
  };

  const onTextInputChanged = (text) => {
    setCurrentStepValid(!!text);
    setGoForwardDisabled(!text);
    setUserSelection(text);

    storeSelectedValue({
      displayValue: text,
      value: text,
    });

    setTextInputValue(text);
  };

  function validateFile(file, maxSize) {
    let errorMsg = '';
    let isValid = true;
    if (file.size > (maxSize * 1024 * 1024)) {
      isValid = false;
      errorMsg = t('largeFile');
    }

    return {
      errorMsg,
      isValid,
    };
  }

  const onAddAttachment = (attList) => {
    const attListToAdd = [];
    attList.forEach((file) => {
      const validation = validateFile(file, MAX_SIZE_MB);
      const extMatch = file.name.match(ALLOWED_EXT);
      const uniqueKey = `${file.name}-${Date.now()}`;
      const Extension = extMatch.slice(1)[0];

      if (extMatch) {
        // adds the new attachment with zero percent
        attListToAdd.push({
          DownloadBinary: '',
          errorMsg: validation.errorMsg,
          Extension,
          file,
          Filename: file.name,
          key: uniqueKey,
          percentage: 0,
          size: file.size,
          valid: validation.isValid,
        });
      }
    });

    setCurrentStepValid(attachments.length > 0 || attListToAdd.length > 0);
    setGoForwardDisabled(attachments.length === 0 && attListToAdd.length === 0);

    const newAttachmentList = [...attachments, ...attListToAdd];

    storeSelectedValue({
      displayValue: newAttachmentList.length,
      value: newAttachmentList,
    });

    setAttachments(newAttachmentList);
    setUserSelection(newAttachmentList);
  };

  const onRemoveAttachment = (attachment) => {
    const newAttachmentList = attachments.filter((att) => att !== attachment);
    setAttachments(newAttachmentList);

    storeSelectedValue({
      displayValue: newAttachmentList.length,
      value: newAttachmentList,
    });

    setCurrentStepValid(newAttachmentList.length > 0);
    setGoForwardDisabled(newAttachmentList.length === 0);
    setUserSelection(newAttachmentList);
  };

  const onAmountInputChanged = ({ target }) => {
    const amount = target.value;

    if (testNumberWithDecimal(amount)) {
      setAmountInput(amount);
      setCurrentStepValid(!!amount && amount > 0);
      setGoForwardDisabled(!amount && amount === 0);
      setUserSelection(amount);

      storeSelectedValue({
        displayValue: currencyFormat({ value: amount }),
        value: amount,
      });
    }
  };

  const onOneOptionSelected = ({ option, validStep = false }) => {
    setOptionSelected(option);
    setCurrentStepValid(validStep);
    setGoForwardDisabled(false);
    setUserSelection({ ...option, validStep });

    const { id, label } = option;

    // special case: users confirms that has the same previous
    // reimbursement information
    if (currentStep.KeyName === STEP_KEY_NAME.confirm_reimb_preference) {
      storeAccountInformationFixed();
    } else {
      storeSelectedValue({
        displayValue: label,
        value: id,
      });
    }
  };

  const onSignatureChanged = (newSignature) => {
    if (!currentStep) {
      return;
    }

    setSignature(newSignature);
    setCurrentStepValid(!!newSignature);
    setGoForwardDisabled(!newSignature);
    setUserSelection(newSignature);

    storeSelectedValue({
      hideDisplayName: true,
      value: newSignature,
    });
  };

  const onSignatureTypeSelection = (option) => () => {
    if (option === SIGNATURE_TYPE.DRAWING) {
      // TODO: Drawing feature
      setCurrentStepValid(false);
      setGoForwardDisabled(true);
    } else {
      setCurrentStepValid(!!signature);
      setGoForwardDisabled(!signature);
    }

    setSignatureTypeSelected(option);
  };

  const onSignatureTextChanged = ({ target: { value } }) => {
    const CLAIM_DETAIL_REGEX = /[:-@[-`]+/;
    const formattedText = value.replace(CLAIM_DETAIL_REGEX, '');
    setSignatureText(formattedText);
  };

  const onRoutingNumberChanged = ({ target }) => {
    const number = target.value;

    setRoutingNumber(number);
    setUserSelection(number);
    setCurrentStepValid(true);
    const numberValidation = validateNumbersOnly(number);
    const routingNumValidation = isRoutingNumberValid(number);

    if (!routingNumValidation) {
      storeSelectedValue({
        displayValue: number,
        value: number,
      });
    }

    setGoForwardDisabled(!numberValidation || !!routingNumValidation);
  };

  const onAccountNumberChanged = ({ target }) => {
    const number = target.value;

    setAccountNumber(number);
    setUserSelection(number);
    setCurrentStepValid(true);
    const numberValidation = validateNumbersOnly(number);

    if (numberValidation) {
      storeSelectedValue({
        displayValue: number,
        value: number,
      });
    }

    setGoForwardDisabled(!numberValidation);
  };

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.height = 80;
    canvas.width = 454;
    const ctx = canvas.getContext('2d');
    ctx.font = '15px Gotham-regular';
    ctx.textAlign = 'center';
    ctx.fillText(signatureText, 220, 45);
    const dataUrl = canvas.toDataURL();

    onSignatureChanged(signatureText ? dataUrl : null);
  }, [signatureText]);

  const onSubmitClaim = () => {
    const { claimsGeneric: { userResponses } } = store;
    dispatch(submitClaim({ userResponses }));
  };

  const onClaimSubmitted = () => {
    setClaimSubmittedVisible(true);
  };

  useEffect(() => {
    const { answered, success } = store.claimsGeneric.submitClaim;

    if (answered) {
      setFinished(true);
      goToNextStep(success);
    }
  }, [store.claimsGeneric.submitClaim]);

  const doToBase64 = async (file) => {
    const binaryDoc = await toBase64(file);
    return binaryDoc;
  };

  async function updateBinaryAttachments() {
    const newAtts = await Promise.all(attachments.map(async (att) => {
      if (att.valid && !att.percentage) {
        const binary = await doToBase64(att.file);
        return {
          ...att,
          DownloadBinary: binary,
          percentage: 100,
        };
      }

      return att;
    }));

    setAttachments(newAtts);
    storeSelectedValue({
      displayValue: newAtts.length,
      value: newAtts,
    });
  }

  useEffect(() => {
    if (!attachments.length) {
      return;
    }

    if (attachments.some((att) => att.valid && !att.percentage)) {
      delay(400)
        .then(() => updateBinaryAttachments());
    }
  }, [attachments]);

  const addAgentMessageWithFormat = (message = '') => {
    const petName = claimsGeneric.selectedPet?.Name;
    const formated = message
      .replace('<Petname>', petName)
      .replace('<pet name>', petName)
      .replace('<payment method>', reimbursementMethodUser)
      .replace(/<new line>/g, '\n');

    setAgentText(formated);
  };

  const resetPreviousValuesSelected = () => {
    setCurrentStepValid(false);
    setGoForwardDisabled(true);
    setUserSelection(null);
    setGoBackDisabled(false);

    setSelectedDate('');
    setClaimTypeSelected(null);
    setTextInputValue('');
    setAttachments([]);
    setAmountInput('');
    setOptionSelected(null);
  };

  const handleChatbotStep = async (step) => {
    resetPreviousValuesSelected();
    let validStep = false;
    let holdResponse = false;

    if (step.MessageText) {
      addAgentMessageWithFormat(step.MessageText);
    }

    if (unknowStepVisible) {
      setUnknowStepVisible(false);
    }

    switch (step.KeyName) {
      case STEP_KEY_NAME.user_has_pets_with_policy:
        validStep = userHasPetsWithPolicyValidation();
        break;

      case STEP_KEY_NAME.error_nopolicy:
      case STEP_KEY_NAME.synthetic_policy_error:
        validStep = false;
        showFinishButton();
        break;

      case STEP_KEY_NAME.user_has_more_than_one_pet:
        validStep = userHasMoreThanOnePet();

        // has only one pet
        if (!validStep) {
          const pet = store.claimsGeneric.petsInfo[0];
          selectPet(pet);

          // but multiple policies
          if (pet.PetValidDates && pet.PetValidDates.length > 1) {
            injectPolicySelectorStep();
            holdResponse = true;
          }
        }
        break;

      case STEP_KEY_NAME.ok_select_pet:
      case STEP_KEY_NAME.ok_select_injury:
      case STEP_KEY_NAME.get_vetfee_attachments:
      case STEP_KEY_NAME.get_vetfee_attachments_s1:
      case STEP_KEY_NAME.ok_other_insurance:
      case STEP_KEY_NAME.ok_select_wellness:
      case STEP_KEY_NAME.confirm_select_check:
      case STEP_KEY_NAME.terms_declined:
      case STEP_KEY_NAME.ok_select_vetfee:
        validStep = true;
        holdResponse = true;
        setCurrentStepValid(true);
        setGoForwardDisabled(false);
        break;

      case STEP_KEY_NAME.get_attachments:
      case STEP_KEY_NAME.get_vetfee_attachments_s2:
      case STEP_KEY_NAME.get_vetfee_attachments_s3:
      case STEP_KEY_NAME.get_vetfee_attachments_vc:
      case STEP_KEY_NAME.get_vetfee_attachments_rw:
      case STEP_KEY_NAME.get_vetfee_attachments_st_s2:
      case STEP_KEY_NAME.get_vetfee_attachments_ar_s2:
      case STEP_KEY_NAME.get_vetfee_attachments_bf:
        if (step.userSelection) {
          setAttachments(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showAttSelector();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.confirm_reimb_preference:
      case STEP_KEY_NAME.get_other_insurance:
      case STEP_KEY_NAME.get_confirm_terms:
      case STEP_KEY_NAME.get_reimb_preference:
      case STEP_KEY_NAME.get_ach_account_type:
        if (step.userSelection) {
          setOptionSelected(step.userSelection);
          setCurrentStepValid(step.userSelection?.validStep);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showMultiOptSelect(currentStep.Input.Options);
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_select_pet:
        if (step.userSelection) {
          setSelectedPet(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }
        showPetSelector();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.syntetic_get_select_policy:
        if (step.userSelection) {
          setPolicySelected(step.userSelection);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        // special case: single pet (no pet selector shown)
        // and multiple policies (going back from history)
        validStep = !(store.claimsGeneric.petsInfo.length === 1
          && selectedPet.PetValidDates.length > 1);
        setCurrentStepValid(validStep);
        holdResponse = true;
        showPolicySelector();
        break;

      case STEP_KEY_NAME.select_claim_type:
        if (step.userSelection) {
          setClaimTypeSelected(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showClaimSelector();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_illness_date:
      case STEP_KEY_NAME.get_onset_date:
      case STEP_KEY_NAME.get_treatment_date:
      case STEP_KEY_NAME.get_wellness_treatment_date:
        if (step.userSelection) {
          setSelectedDate(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showDateInput();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_illness_desc:
        if (step.userSelection) {
          setTextInputValue(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showTextInput();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_other_insurance_name:
        if (step.userSelection) {
          setTextInputValue(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showTextInputSingleLine();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_claim_amount:
        if (step.userSelection) {
          setAmountInput(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showClaimAmountInput();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_ach_routing:
        if (step.userSelection) {
          setRoutingNumber(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showRoutingNumber();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.get_ach_account:
        if (step.userSelection) {
          setAccountNumber(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showAccountNumber();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.show_summary:
        holdResponse = true;
        setCurrentStepValid(true);
        setGoForwardDisabled(false);
        showClaimSummary();
        break;

      case STEP_KEY_NAME.get_signature:
        if (step.userSelection) {
          setSignature(step.userSelection);
          setCurrentStepValid(true);
          setGoForwardDisabled(false);
          setUserSelection(step.userSelection);
        }

        showSignAndSubmit();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.submit_claim:
        setGoBackDisabled(true);
        onSubmitClaim();
        holdResponse = true;
        break;

      case STEP_KEY_NAME.claim_filing_success:
      case STEP_KEY_NAME.claim_filing_error:
        holdResponse = true;
        onClaimSubmitted();
        setGoForwardDisabled(false);
        setGoBackDisabled(true);
        break;

      default:
        validStep = false;
        holdResponse = true;
        setUnknowStepVisible(true);
        break;
    }

    if (holdResponse) {
      // step will be completed by the required component
      return;
    }

    goToNextStep(validStep);
  };

  useEffect(() => {
    if (!currentStep) {
      return;
    }

    handleChatbotStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    if (store.claimsGeneric.policyByDate) {
      const { policyByDate } = store.claimsGeneric;
      selectPolicy(policyByDate);
      const nextStep = getNextStep(true);
      storeStepInHistory({
        step: nextStep,
        stepWithSelection: {
          ...currentStep,
          userSelection,
        },
      });
    }
  }, [store.claimsGeneric.policyByDate]);

  useEffect(() => {
    if (store.claimsGeneric.policyError) {
      setCurrentStep({
        KeyName: STEP_KEY_NAME.synthetic_policy_error,
        MessageText: store.claimsGeneric.policyError,
      });
    }
  }, [store.claimsGeneric.policyError]);

  return {
    ...coverageDateRange,
    accountNumber,
    accountNumberVisible,
    agentImage,
    agentText,
    amountInput,
    amountInputVisible,
    attachments,
    attSelectorVisible,
    backButtonLabel,
    claimSelectorVisible,
    claimsGeneric,
    claimSubmittedVisible,
    claimSummaryInfo,
    claimSummaryVisible,
    claimTypes,
    claimTypeSelected,
    currentStep,
    currentStepNumber,
    dateInputVisible,
    finishButtonVisible,
    finished,
    goBackDisabled: goBackDisabled || stepsHistory.length === 0,
    goForwardisabled,
    loadingAdditionalSteps: claimsGeneric.loadingAdditionalSteps,
    loadingPolicyByDate: claimsGeneric.loadingPolicyByDate,
    multiOptSelectVisible,
    multipleOptions,
    nextButtonLabel,
    numberOfSteps,
    onAccountNumberChanged,
    onAddAttachment,
    onAmountInputChanged,
    onNextStepClick,
    onOneOptionSelected,
    onPetItemPress,
    onPolicyItemPress,
    onPreviousStepClick,
    onRemoveAttachment,
    onRoutingNumberChanged,
    onSelectClaimType,
    onSelectedValidDate,
    onSignatureTextChanged,
    onSignatureTypeSelection,
    onTextInputChanged,
    optionSelected,
    pets,
    petSelectorVisible,
    policyBrandName,
    policySelected,
    policySelectorVisible,
    routingNumber,
    routingNumberVisible,
    selectedDate,
    selectedPet,
    selectedPetPolicies,
    signAndSubmitVisible,
    signature,
    signatureText,
    signatureTypeSelected,
    submitClaim: store.claimsGeneric.submitClaim,
    t,
    textInputSingleLineVisible,
    textInputValue,
    textInputVisible,
    twoOptions,
    twoOptSelectVisible,
    unknowStepVisible,
  };
};

export { useFileClaimbotScreen };
