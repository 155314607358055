import { select } from 'redux-saga/effects';

export function* useCustomerApiV1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV1;
}

export function* useCustomerInboxDocsApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV2InboxAndDocs;
}

export function* useClaimApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV2;
}

export function* useCustomerApiV2MyPetsForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV2MyPets;
}

export function* useClaimsApiV1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV1;
}

export function* useClaimsApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV2;
}

export function* useCustomerApi150617V1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomer150617ApiV1;
}
