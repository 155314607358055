import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowRight }
from '../../assets/arrow-right-to-paint.svg';

import './ViewMoreButton.css';

const ViewMoreButton = ({
  className = '',
  text = '',
  onClick = () => { },
  isLink = false,
  to = '',
}) => {
  const Wrapper = useCallback(({ children }) => {
    if (isLink) {
      return (
        <Link className={`View-more-button ${className}`} to={to}>
          {children}
        </Link>
      );
    }

    return (
      <button
        className={`View-more-button ${className}`}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }, [isLink, to]);

  return (
    <Wrapper>
      <span>{text}</span>

      <ArrowRight className="Branded-icon" />
    </Wrapper>
  );
};

export { ViewMoreButton };
