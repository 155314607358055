import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useIsAuthenticated } from '@azure/msal-react';
import {
  refreshApiTokensReset,
  refreshToken,
  startup,
  startupFinished,
} from './actions';
import SessionRouter from './components/navigation/SessionRouter';
import PublicRouter from './components/navigation/PublicRouter';
import CustomModal from './components/common/CustomModal';
import { ImpersonateSession } from './components/common/ImpersonateSession';
// TODO: FPA-14595
// import { ChatWidgetEmbed } from './components/common/ChatWidgetEmbed';
// import {
//   CHANGE_COVERAGE_WEB,
//   DOWNLOAD_MOBILE_APPS,
//   LOGIN,
//   POST_SHARE,
// } from './components/navigation/routes.constants';
// import { isChangeCoverage } from './components/changeCoverage/utils';
// import { useResponsive } from './hooks/useResponsive';
import { useOneIncScript } from './hooks/useOneIncScript';
import { useB2CInfo } from './hooks';
import { useMultipleScopes } from './services/utils';

const AppRouter = () => {
  const { t } = useTranslation('sessionRouter');
  const dispatch = useDispatch();
  // const { isMobile, isTablet } = useResponsive();
  useOneIncScript();

  const pathname = window.location.pathname.split('/')[1];
  const { search } = window.location;
  const store = useSelector(({
    policies,
    session,
  }) => ({ policies, session }), shallowEqual);
  const { isExpired, isTokenRefreshed } = store.session;
  const { getAllTokens, logoutB2C } = useB2CInfo();
  const isAuthenticated = useIsAuthenticated();
  let redirectPath = `${pathname}${search}`;

  if (pathname === 'impersonate') {
    redirectPath = '';
  }

  useEffect(() => {
    switch (pathname) {
      case 'login':
        logoutB2C();
        break;
      case 'paymentMethod':
        localStorage.clear();
        dispatch(startupFinished());
        break;
      default:
        dispatch(startup());
    }

    return () => null;
  }, []);

  useEffect(() => {
    if (isExpired) {
      dispatch(refreshToken());
    }
  }, [isExpired]);

  useEffect(() => {
    if (isTokenRefreshed) {
      window.location.reload();
    }
  }, [isTokenRefreshed]);

  const isImpersonate = useMemo(() => store.session
    .impersonatingAdminEmail !== '',
  [store.session.impersonatingAdminEmail]);

  useEffect(() => {
    if (useMultipleScopes && isAuthenticated && store.session.data !== null) {
      getAllTokens({ isImpersonate });
    }
  }, [
    getAllTokens,
    isAuthenticated,
    isImpersonate,
    store.session.data,
    useMultipleScopes,
  ]);

  useEffect(() => {
    if (store.session.isRefreshingApiTokens && isAuthenticated) {
      getAllTokens({ isImpersonate });
      dispatch(refreshApiTokensReset());
    }
  }, [
    isImpersonate,
    store.session.data,
    store.session.isRefreshingApiTokens,
  ]);

  function renderPageContent() {
    if (store.session.startingUp) {
      return <></>;
    }

    const hasSession = store.session.userInfo && !store.session.privateToken
      && isAuthenticated;

    if (hasSession) {
      return <SessionRouter i18n={t} redirect={redirectPath} />;
    }

    if (!isAuthenticated) {
      return <PublicRouter />;
    }

    return <PublicRouter />;
  }

  return (
    <Router>
      {renderPageContent()}

      {/* TODO: disabled chat FPA-14595 */}
      {/* <ChatWidgetEmbed
        blackListRoutes={[
          DOWNLOAD_MOBILE_APPS,
          `${CHANGE_COVERAGE_WEB}/:policyNumber/options`,
          POST_SHARE,
          LOGIN,
          '/',
        ]}
        hideChat={isChangeCoverage() && (isMobile || isTablet)}
      /> */}

      <CustomModal />

      <ImpersonateSession />
    </Router>
  );
};

export default AppRouter;
