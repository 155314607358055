import { all, call, put, takeLatest } from 'redux-saga/effects';
import { post } from '../services/api';
import {
  getMultibrandWebView as GET_MULTIBRAND_WEB_VIEW,
  webViewMultibrandAttempt,
  webViewMultibrandFailed,
  webViewMultibrandSuccess,
} from '../actions';
import { MARKETING_CHANNEL_ID } from '../theme/theme.constants';
import { WEB_VIEW_TYPES } from '../constants';

function* getWebViewUrl({
  companyId = 0,
  dispatch,
  petId,
  policyId,
  policyNumber,
  webViewType = 0,
}) {
  try {
    const url = 'api/MultiBrand/WebView';
    const body = {
      CompanyId: companyId,
      Params: {},
      WebViewType: webViewType,
    };

    if (policyId) {
      body.Params.PolicyId = policyId;
    }
    if (petId) {
      body.Params.PetId = petId;
    }
    if (policyNumber) {
      body.Params.PolicyNumber = policyNumber;
    }

    const response = yield call(post, dispatch, url, body);

    return response;
  } catch (_) {
    return null;
  }
}

export function* getMultibrandWebView(dispatch, action) {
  yield put(webViewMultibrandAttempt());

  const {
    companyId = MARKETING_CHANNEL_ID.figo,
    petId,
    policyId,
    policyNumber,
    webViewType = WEB_VIEW_TYPES.billingAndPayment,
  } = action.payload;

  const urlResponse = yield getWebViewUrl({
    companyId,
    dispatch,
    petId,
    policyId,
    policyNumber,
    webViewType,
  });

  if (urlResponse && urlResponse.IsValid) {
    yield put(webViewMultibrandSuccess({
      data: urlResponse.Data,
      webViewType,
    }));
  } else {
    yield put(webViewMultibrandFailed());
  }
}

export function* multibrandSaga(store) {
  yield all([
    takeLatest(GET_MULTIBRAND_WEB_VIEW, getMultibrandWebView, store.dispatch),
  ]);
}
