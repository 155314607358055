import { all, call, put } from 'redux-saga/effects';
import { apiCallWithApiKey, get } from '../services/api';
import {
  breedMultibrandValidationFailed,
  breedMultibrandValidationSucceed,
  orcaGetPowerUpAttempt,
  orcaGetPowerUpFailed,
  orcaGetPowerUpLoaded,
} from '../actions';

export function* getOrcaPowerUp(dispatch, { payload }) {
  try {
    yield put(orcaGetPowerUpAttempt());

    const { productKey } = payload;
    const getPublished = process.env.REACT_APP_NAME === 'Prod';
    const url =
      `api/Contentful/GetPowerDetails/${productKey}/${getPublished}`;

    const response = yield call(
      apiCallWithApiKey,
      url,
      dispatch,
    );

    if (response.success && response.IsValid) {
      yield put(orcaGetPowerUpLoaded(response.Data));
    } else {
      yield put(orcaGetPowerUpFailed());
    }
  } catch (e) {
    yield put(orcaGetPowerUpFailed());
  }
}

export function* validateBreedMultibrand(dispatch, { payload }) {
  try {
    const { petId } = payload;
    // Validate orca double active policies
    const url = 'api/Orca/Customer/HasMultiplePolicies';
    // Validate pet breedId from figo quote
    const urlPetBreed = `api/Breeds/IsValidPetBreed/${petId}`;

    const [response, responsePetBreed] = yield all([
      call(get, dispatch, url),
      call(get, dispatch, urlPetBreed),
    ]);

    if (response.success
      && response.IsValid
      && responsePetBreed.success
    ) {
      dispatch(breedMultibrandValidationSucceed({
        hasMultipleOrcaPolicies: response.Data.HasMultipleOrcaPolicies,
        petValidBreed: responsePetBreed?.Message,
        policyProvider: response.Data.PolicyProvider,
      }));
    } else {
      dispatch(breedMultibrandValidationFailed());
    }
  } catch (e) {
    dispatch(breedMultibrandValidationFailed());
  }
}
