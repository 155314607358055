import { createActions } from 'redux-actions';

const actions = createActions({
  ACCEPT_TERMS_OF_SERVICE: () => { },
  ATTEMPT_PRIVATE_TOKEN: () => { },
  ATTEMPT_TEMPORARY_TOKEN: () => { },
  BANNER_VISIBLE_CHANGED: (data) => (data),
  CHECK_SESSION: (data) => data,
  CLEAR_ERROR: () => { },
  CLOSE_MODAL: () => { },
  CONVERT_IMPERSONATE_CUSTOMER_ID: (data) => data,
  CUSTOMER_INFORMATION_FAILED: () => { },
  CUSTOMER_INFORMATION_LOADED: (data) => data,
  DO_LOGIN: (data) => data,
  DO_LOGIN_IMPERSONATE: (data) => data,
  DO_LOGOUT: () => { },
  GET_AUTH_CONFIG_ATTEMPT: () => {},
  GET_AUTH_CONFIG_LOADED: (data) => data,
  GET_AUTH_METHOD_CONFIGURATION: () => {},
  GET_PRIVATE_TOKEN: (data) => data,
  GET_PROFILE: (data) => data,
  GET_TEMPORARY_TOKEN: () => { },
  LOAD_CUSTOMER_INFORMATION: (data) => data,
  LOAD_DATA_FOR_PRIVATE_TOKEN: () => { },
  LOG_IN: (data) => data,
  LOG_OUT: () => { },
  PRIVATE_TOKEN_COMPLETED: () => { },
  PRIVATE_TOKEN_FAILED: () => { },
  REFRESH_API_TOKENS: () => { },
  REFRESH_API_TOKENS_RESET: () => { },
  REFRESH_TOKEN: () => { },
  RESET_LOGIN_ERROR: () => { },
  SAVE_IMPERSONATE_CUSTOMER_ID: (data) => data,
  SAVE_USER_INFO: (data) => data,
  SAVE_USER_TERMS_OF_SERVICE: (data) => data,
  SESSION_ERROR: (data) => data,
  SESSION_EXPIRED: () => { },
  SET_PRIVATE_TOKEN: (data) => data,
  SET_TEMPORARY_TOKEN: (data) => data,
  STARTUP_FINISHED: () => { },
  TEMPORARY_TOKEN_COMPLETED: () => { },
  TEMPORARY_TOKEN_FAILED: () => { },
  TEMPORARY_TOKEN_RESET: () => { },
  TERMS_OF_SERVICE_ACCEPTED: () => { },
  TOKEN_CLAIM_LOADED: () => { },
  TOKEN_CUSTOMER_LOADED: () => { },
  TOKEN_REFRESHED: () => { },
  UPDATE_SESSION: (data) => data,
});

export const {
  acceptTermsOfService,
  attemptPrivateToken,
  attemptTemporaryToken,
  bannerVisibleChanged,
  checkSession,
  clearError,
  closeModal,
  convertImpersonateCustomerId,
  customerInformationFailed,
  customerInformationLoaded,
  doLogin,
  doLoginImpersonate,
  doLogout,
  getAuthConfigAttempt,
  getAuthConfigLoaded,
  getPrivateToken,
  getProfile,
  getTemporaryToken,
  loadCustomerInformation,
  loadDataForPrivateToken,
  logIn,
  logOut,
  oneTimePasscodeFailed,
  oneTimePasscodeRestarted,
  oneTimePasscodeValidated,
  privateTokenCompleted,
  privateTokenFailed,
  refreshToken,
  refreshApiTokens,
  refreshApiTokensReset,
  resetLoginError,
  saveImpersonateCustomerId,
  saveUserInfo,
  saveUserTermsOfService,
  sendOneTimePasscode,
  sendOneTimePasscodeFailed,
  sendOneTimePasscodeSuccess,
  sessionError,
  sessionExpired,
  setPrivateToken,
  setTemporaryToken,
  startupFinished,
  temporaryTokenCompleted,
  temporaryTokenFailed,
  temporaryTokenReset,
  termsOfServiceAccepted,
  tokenClaimLoaded,
  tokenCustomerLoaded,
  tokenRefreshed,
  updateSession,
} = actions;
