import { call, put } from 'redux-saga/effects';
import {
  addedToFavorites,
  addToFavoritesFailed,
  addCustomToFavoritesFailed,
  addPointInterestFailed,
  getAllFavoritesAttempt,
  getAllFavorites as getFavorites,
  setFavorites,
  validateZipCodeAttempt,
  validateZipCodeFailed,
  validateZipCodeSuccess,
  vetsSearchCleared,
  vetsSearched,
  removeVetSuccess,
  removeVetFailed,
  hasPrimaryVetChecked,
  primaryVetSaved,
  primaryVetSaveFailed,
} from '../actions';
import { apiCallWithApiKey, del, get, post } from '../services/api';

export function* searchVets(dispatch, { payload }) {
  const { zipCode, query } = payload;

  const queryParms = {
    category: 'veterinary_care',
    latitude: 0,
    location: '',
    longitude: 0,
    radius: 643737,
    searchPetFriendly: true,
    sortBy: 'distance',
    term: query || '',
    zipCode,
  };

  const response = yield call(
    get,
    dispatch,
    'api/Places/SearchPlaceWithSummary',
    queryParms,
  );

  if (response.IsValid) {
    yield put(vetsSearched(response.Data.Places, zipCode));
  } else {
    yield put(vetsSearchCleared());
  }
}

export function* addToFavorites(dispatch, { payload }) {
  const { categoryId, placeId, providerId } = payload;

  const response = yield call(
    post,
    dispatch,
    'api/Favorites/Add',
    {
      categoryId,
      placeId,
      providerId,
    },
  );

  if (response.IsValid) {
    yield put(addedToFavorites(response.Data));
    yield put(getFavorites());
  } else {
    yield put(addToFavoritesFailed());
  }
}

export function* addCustomToFavorites(dispatch, { payload }) {
  const { categoryId, providerId, name, city, stateId } = payload;

  const addPointInterestURL = 'api/PointInterest/Add';
  const addFavoritesURL = 'api/Favorites/Add';

  const body = {
    city,
    name,
    providerId,
    state: stateId,
  };

  const addPointInterestResponse = yield call(
    apiCallWithApiKey,
    addPointInterestURL,
    {
      body,
      method: 'POST',
    },
    dispatch,
  );

  if (addPointInterestResponse.IsValid && addPointInterestResponse.Data) {
    const { PointInterestId } = addPointInterestResponse.Data;

    const response = yield call(
      post,
      dispatch,
      addFavoritesURL,
      {
        categoryId,
        placeId: PointInterestId,
        providerId,
      },
    );

    if (response.IsValid) {
      yield put(addedToFavorites(response.Data));
      yield put(getFavorites());
    } else {
      yield put(addCustomToFavoritesFailed(response.Data));
    }
  } else {
    yield put(addPointInterestFailed(addPointInterestResponse.Data));
  }
}

export function* validateZipCode({ payload }) {
  const { zipCode } = payload;

  yield put(validateZipCodeAttempt());

  const response = yield call(
    apiCallWithApiKey,
    `/api/State/ValidateZipcode/${zipCode}`,
  );

  if (response.IsValid && response.Data) {
    yield put(validateZipCodeSuccess());
  } else {
    yield put(validateZipCodeFailed());
  }
}

export function* getAllFavorites(dispatch) {
  yield put(getAllFavoritesAttempt());

  const response = yield call(
    get,
    dispatch,
    'api/Favorites/GetAll',
    {
      categoryId: 1,
      // The value provided as userDateTime makes no difference in the response,
      // as long as it's properly formatted as a date. So this is just a random
      // date... Remember remember the fifth of november...
      userDateTime: '1605-11-05 08:50:57',
    },
  );

  if (response.IsValid && response.Data) {
    yield put(setFavorites(response.Data));
  } else {
    yield put(setFavorites([]));
  }
}

export function* removeVet(dispatch, { payload }) {
  const { placeId } = payload;
  const url = `api/Favorites/${placeId}`;
  const response = yield call(del, dispatch, url);

  if (response.success && response.IsValid) {
    yield put(removeVetSuccess());
  } else {
    yield put(removeVetFailed());
  }
}

export function* checkIfHasPrimaryVet(dispatch) {
  const url = 'api/User/GetUserHasVetAdd';
  const response = yield call(get, dispatch, url);
  yield put(hasPrimaryVetChecked(response.Data));
}

export function* searchPrimaryVets({ payload }) {
  const { zipCode, query } = payload;
  const url = `api/Quote/Veterinarians?zipcode=${zipCode}&name=${query}`;
  const response = yield call(apiCallWithApiKey, url);
  yield put(vetsSearched(response.Data || [], zipCode));
}

export function* savePrimaryVet(dispatch, { payload }) {
  const {
    selectedVetId = 0,
    isCustomVet = false,
    vetCompany = '',
    vetCity = '',
    stateId = '',
  } = payload;
  const url = 'api/User/AddVet';
  let body = {};
  if (isCustomVet) {
    body = {
      HasManualVet: isCustomVet,
      StateId: stateId,
      VetCity: vetCity,
      VetCompany: vetCompany,
    };
  } else {
    body = {
      SelectedVetID: selectedVetId,
    };
  }

  const response = yield call(post, dispatch, url, body);
  if (response.success && response.IsValid) {
    yield put(primaryVetSaved());
  } else {
    yield put(primaryVetSaveFailed());
  }
}
