import moment from 'moment';
import { camelCase, startCase } from 'lodash';
import {
  DESIGN_TOKEN_TYPE,
  FILES_TYPES_ACCEPTED,
  FILTER_TYPES,
  MAX_ZIP_LENGTH,
  PROTECTED_FOLDERS,
} from '../constants';
import { MARKETING_CHANNEL_ID } from '../theme/theme.constants';
import { serializeForUri } from './api';
import app from '../app.json';

export function doNothing() {
  // Does exactly what it promises.
  // ... what is "nothing" anyway? https://bit.ly/2Hwo3Vj
}

// This function uses setTimeout but can be combined with await in an async
// function to wait for some time before continuing execution.
export function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function maskPhoneNumber(string = '') {
  if (!string) {
    return '';
  }

  let phone = string.replace(/[^0-9]/g, '');
  const areaCodeMatch = /^(\d{3})(?=\d+)/.exec(phone);
  const firstPartMatch = /^\d{3}(\d{1,3})/.exec(phone);
  const secondPartMatch = /^\d{6}(\d{1,4})/.exec(phone);

  if (areaCodeMatch) {
    phone = `(${areaCodeMatch[1]})`;

    if (firstPartMatch) {
      phone += `-${firstPartMatch[1]}`;

      if (secondPartMatch) {
        phone += `-${secondPartMatch[1]}`;
      }
    }
  }

  return phone;
}

export function findSelectedModifier({ id, modifiers = [] }) {
  const contentModifierSelected = modifiers
    ?.find((element) => element.id === id);

  const isChecked = contentModifierSelected
    ? contentModifierSelected.isSelected : false;

  return isChecked;
}

export function getDateBasicFormat(date, format = 'M/DD/YYYY') {
  return moment(date).format(format);
}

export function isEmailValid(email = '') {
  const regexString = '^[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)*@'
    + '[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)*\\.[a-zA-Z0-9]{1,}$';
  const regex = new RegExp(regexString);

  return regex.test(String(email.trim()).toLowerCase());
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function currencyFormat({ fixed = 2, value = 0 }) {
  if (Number.isNaN(Number(value))) {
    return value;
  }

  if (value === 0) {
    return `$${value.toFixed(fixed)}`;
  }

  let formattedValue = `${Math.abs(value)
    .toFixed(fixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  formattedValue = value > -1
    ? `$${formattedValue}`
    : `-$${formattedValue}`;

  return formattedValue;
}

export function testNumberWithDecimal(value) {
  const REGEX_NUMBER = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;

  return REGEX_NUMBER.test(value) || !value;
}

export function validateNumbersOnly(input) {
  const ONLY_NUMBER_REGEX = /^\d+$/g;

  return input && input.match(ONLY_NUMBER_REGEX);
}

export function validateLettersOnly(input) {
  const ONLY_LETTERS_REGEX = /^[a-z A-Z]*$/g;

  return input && input.match(ONLY_LETTERS_REGEX);
}

export function validateAlphanumeric(value) {
  const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9]*$/;

  return REGEX_ALPHANUMERIC.test(value) || !value;
}

export function isValidDiamondStreet(value) {
  const newValue = String(value).replace('P.O.', '').replace('P. O.', '');

  // eslint-disable-next-line max-len
  const REGEX_STREET_ADDRESS = /^\s*((([a-zA-Z]+\s){1}((?=.*[0-9]+))|([0-9]+\s){1}((?=.*[a-zA-Z]+)))|((?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)+\s){1}((?=.*[0-9a-zA-Z]+)))+.*\s*$/;
  const isStreetAddressValid = REGEX_STREET_ADDRESS.test(newValue.trim());

  return isStreetAddressValid;
}

// For more information about the Routing validation see:
// http://en.wikipedia.org/wiki/Routing_transit_number
// value for testing: 021000021
export function isRoutingNumberValid(routing) {
  if (routing.length !== 9) {
    return 'errors.mustBeANineDigitNumber';
  }

  const checksumTotal =
    (
      7 * (parseInt(routing.charAt(0), 10)
        + parseInt(routing.charAt(3), 10)
        + parseInt(routing.charAt(6), 10))
    )
    + (
      3 * (parseInt(routing.charAt(1), 10)
        + parseInt(routing.charAt(4), 10)
        + parseInt(routing.charAt(7), 10))
    )
    + (
      9 * (parseInt(routing.charAt(2), 10)
        + parseInt(routing.charAt(5), 10)
        + parseInt(routing.charAt(8), 10))
    );

  const checksumMod = checksumTotal % 10;
  if (checksumTotal === 0 || checksumMod !== 0) {
    return 'errors.invalidBankRoutingNumber';
  }

  return '';
}

export function isMarkelPolicy(policy) {
  return policy.startsWith('FGO');
}

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0) {
      encoded += '='.repeat(4 - (encoded.length % 4));
    }
    resolve(encoded);
  };
  reader.onerror = (error) => reject(error);
});

export function isFPIPolicy(policy) {
  if (!policy) {
    return false;
  }

  return policy.startsWith('FPI');
}

export function getMimefile(extension) {
  switch (extension) {
    case FILES_TYPES_ACCEPTED.pdf:
      return 'application/pdf';
    case FILES_TYPES_ACCEPTED.jpg:
    case FILES_TYPES_ACCEPTED.jpeg:
    case FILES_TYPES_ACCEPTED.png:
      return 'image/jpeg';
    case FILES_TYPES_ACCEPTED.doc:
    case FILES_TYPES_ACCEPTED.docx:
      return 'application/msword';
    case FILES_TYPES_ACCEPTED.xls:
    case FILES_TYPES_ACCEPTED.xlsx:
      return 'application/vnd.ms-excel';
    case FILES_TYPES_ACCEPTED.csv:
      return 'text/csv';
    case FILES_TYPES_ACCEPTED.txt:
      return 'text/plain';
    case FILES_TYPES_ACCEPTED.html:
      return 'text/html';
    default:
      return '';
  }
}

export async function downloadFileInPage({ fileName, fileUrl, blobUrl }) {
  try {
    let url = '';
    if (blobUrl) {
      url = blobUrl;
    } else {
      const response = await fetch(
        fileUrl,
        { method: 'get', referrerPolicy: 'no-referrer' },
      );
      const blob = await response.blob();
      url = window.URL.createObjectURL(blob);
    }

    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    aElement.href = url;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    window.URL.revokeObjectURL(url);
  } catch (_) {
    doNothing();
  }
}

export const showBannerConfig = app.showBanner;

export function loadEmbeddedScript({
  onLoadCallback,
  scriptAsync,
  scriptDefer,
  scriptId,
  scriptInnerHTML,
  scriptSrc,
  scriptType = 'text/javascript',
}) {
  if (document.getElementById(scriptId)) {
    return;
  }

  let script = document.createElement('script');

  if (scriptType === 'text/css') {
    script = document.createElement('style');
  }

  script.id = scriptId;
  script.type = scriptType;
  if (scriptSrc) {
    script.src = scriptSrc;
  }

  script.async = scriptAsync;
  script.defer = scriptDefer;

  if (scriptInnerHTML) {
    script.innerHTML = scriptInnerHTML;
  }

  if (onLoadCallback) {
    script.onload = () => onLoadCallback();
  }

  document.body.appendChild(script);
}

export function removeEmbeddedScripts(...scriptIds) {
  scriptIds.forEach((scriptId) => {
    const script = document.getElementById(scriptId);

    if (script) {
      script.remove();
    }
  });
}

export function getMaskedAccountNumber(accountNumber, subFix = 'xxxxxxxx') {
  const LAST_4_DIGITS_REGEX = /.(?=.{4})/g;

  return `${subFix}${accountNumber.replace(LAST_4_DIGITS_REGEX, '')}`;
}

export const isPaycheck = app.paycheck;

export const {
  useMultipleScopes,
  useNewClaimbot,
  useSecondaryPetParent,
  useTrackingEvents,
} = app;

export function isMobile() {
  /* eslint-disable max-len */

  const userAgent = navigator.userAgent;
  const userAgentPart = userAgent.substr(0, 4);

  return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(userAgentPart);
}

export function removeInvalidSpacesOnNames(name) {
  return name.split(' ').filter((item) => !!item).join(' ');
}

export function getMonthAndDayFromDate(value) {
  return moment(value).format('MMMM DD');
}

export function getDateForPostShare(value) {
  return moment(Number(value)).format('MMM DD [at] h:mm A');
}

export function getNameInitials(name = '') {
  if (!name) {
    return '';
  }

  const names = name.replace(/[^a-zA-Z0-9 ]/g, '').split(' ');
  let initials = '';
  if (names.length > 1) {
    initials = names[0].slice(0, 1) + names[1].slice(0, 1);
    initials.toUpperCase();
  } else {
    initials = names[0].slice(0, 2);
  }
  return initials;
}

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const createImage = (url) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.setAttribute('crossOrigin', 'anonymous');
  image.src = url;
});

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = maxSize;

  // set each dimensions to double largest dimension
  // to allow for a safe area for the image to rotate
  // in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on
  // image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size
  // this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  return canvas.toDataURL('image/jpeg');
}

export function getNullSafeDate(date, format = 'M/DD/YYYY') {
  if (date) {
    return getDateBasicFormat(date.split('T')[0], format);
  }

  return 'N/A';
}

export function goToGetQuotesD2C({
  impersonate = false,
  impersonatingAdminEmail = '',
  LoginEmail,
  petsOrderIds,
  publicToken,
}) {
  if (petsOrderIds && petsOrderIds.length) {
    const path = 'quoting/getQuote';
    const impersonatingEmail = impersonate ? impersonatingAdminEmail : null;
    const params = {
      impersonate,
      impersonatingAdminEmail: impersonatingEmail,
      orderId: petsOrderIds.join(','),
    };

    const paramsUriD2C = serializeForUri(params);
    const getQuoteUrl = `${process.env.REACT_APP_D2C}/${path}`
      + `?${paramsUriD2C}&publicToken=${publicToken}&email=${LoginEmail}`;

    window.open(getQuoteUrl, '_blank');
  } else {
    window.open(`${process.env.REACT_APP_D2C}/getQuote`, '_blank');
  }
}

export function validatePhone(string) {
  if (!string) {
    return false;
  }

  return string.replace(/[()-]/g, '').length === 10;
}

export function base64ToObject(str, defaultValue) {
  try {
    return str ? JSON.parse(window.atob(str)) : defaultValue;
  } catch (_) {
    return defaultValue;
  }
}

export function objectToBase64(obj) {
  return window.btoa(JSON.stringify(obj));
}

/**
 * This function returns an array with the name params on the given route
 * @param {string} url Must have the format /urlexample/:parameter1/:parametern
 * @return {array} Returns the next format ["parameter1", "parameter2"]
 */
export function getRouteParams(url) {
  const q = [];
  url.replace(/:([A-Za-z0-9_]+)/g, (_, k) => {
    q.push(k);
  });
  return q;
}

export function sortListByName({ fieldName = '', list = [], ascendent = true }) {
  return list.sort((prev, current) => {
    const lastName = String(prev[fieldName]).toUpperCase();
    const newName = String(current[fieldName]).toUpperCase();

    if (ascendent) {
      if (lastName < newName) {
        return -1;
      }

      if (lastName > newName) {
        return 1;
      }
    }

    if (lastName < newName) {
      return 1;
    }

    if (lastName > newName) {
      return -1;
    }

    return 0;
  });
}

export function sortListByDate({ fieldName = '', list = [], ascendent = true }) {
  return list.sort((prev, current) => {
    const prevDate = new Date(prev[fieldName].split('T')[0]);
    const currentDate = new Date(current[fieldName].split('T')[0]);

    if (ascendent) {
      return currentDate - prevDate;
    }

    return prevDate - currentDate;
  });
}

export function sortInboxAndDocsFileList({ rawList, sortType, ascendent = true }) {
  const fileList = rawList
    .filter((file) => !!file.Metadata.Extension) || [];
  const folderList = rawList
    .filter((folder) => !folder.Metadata.Extension) || [];

  let folderListSorted = [];
  let fileListSorted = [];

  switch (sortType) {
    case FILTER_TYPES.name:
      return sortListByName({ ascendent, fieldName: 'Name', list: rawList });
    case FILTER_TYPES.date:
      return sortListByDate({ ascendent, fieldName: 'LastUpdate', list: rawList });
    case FILTER_TYPES.size:
      return rawList.sort((prev, current) => (ascendent
        ? prev.Metadata.Size - current.Metadata.Size
        : current.Metadata.Size - prev.Metadata.Size));
    default:
      folderListSorted = sortListByName({ fieldName: 'Name', list: folderList });
      fileListSorted = sortListByName({ fieldName: 'Name', list: fileList });
      return [...folderListSorted, ...fileListSorted];
  }
}

export const addOrRemoveItemFromList = ({
  itemList = [],
  newItem,
}) => {
  const exists = itemList.includes(newItem);
  let newListResponse = [];

  if (exists) {
    const filteredList =
      itemList.filter((element) => element !== newItem);
    newListResponse = filteredList;
  } else {
    newListResponse = [...itemList, newItem];
  }

  return newListResponse;
};

export function toPascalCase(camelCaseStr) {
  return camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
}

export function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';

  const kiloByte = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const index = Math.floor(Math.log(bytes) / Math.log(kiloByte));

  return `${parseFloat((bytes / (kiloByte ** index)).toFixed(2))} ${sizes[index]}`;
}

export const generateFileTreeOptions = ({ folders, level, hiddenFolderId = 0 }) => {
  let newArr = [];
  folders.forEach((item) => {
    if (PROTECTED_FOLDERS.includes(String(item.Id)) || item.Id === hiddenFolderId) {
      return;
    }

    newArr = newArr.concat({
      id: item.Id,
      label: item.Name,
      level,
    });
    // TODO: Remove 'Childrens' validation when v2 is complete
    if ((item?.Childrens && item?.Childrens.length)
      || (item?.Folders && item.Folders?.length)) {
      newArr = newArr.concat(...generateFileTreeOptions({
        folders: item?.Childrens || item?.Folders,
        hiddenFolderId,
        level: level + 1,
      }));
    }
  });
  return newArr;
};

export function fileSizeToMB({ fileSize }) {
  return Number(fileSize / (1024 * 1024)).toFixed(2);
}

/**
 * Returns 1 if the words does not need the adaptation, returns 2 otherwise
 * @param {string} word - The word to be adapted
 */
export function possesiveAdaptationI18n(word) {
  return word && word.length && word[word.length - 1].toLowerCase() === 's' ? 2 : 1;
}

/**
 * @param {string} stringToClean
 * @returns {string} The same stringToClean without emojis
 */
export function removeEmojis(stringToClean) {
  // eslint-disable-next-line max-len
  const REGEX_EMOJIS = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return stringToClean.replace(REGEX_EMOJIS, '');
}

export function isNonFigoUser(company = {}) {
  return company && company.Id !== DESIGN_TOKEN_TYPE.THEME_FIGO;
}

export function getMinutesFromInitialTime(initialValue) {
  return moment
    .duration(moment().diff(initialValue)).asMinutes().toFixed(2);
}

export function getClaimDataForMixPanel({
  date,
  selectedPet,
  policyNumber,
}) {
  const claimData = [];

  if (selectedPet) {
    claimData.push(`petname-${selectedPet.Name}`);
  }

  if (policyNumber) {
    claimData.push(`policynumber-${policyNumber}`);
  }

  if (date) {
    claimData.push(`datofloss-${getDateBasicFormat(new Date(Number(date)))}`);
  }

  return claimData.join(', ');
}

export function validateZipCode(zipCode = '') {
  return zipCode.replace(/\D/g, '').slice(0, MAX_ZIP_LENGTH);
}

export function formatDate(date) {
  return !date ? '-' : moment(date.split('T')[0]).format('MM/DD/YYYY');
}

export function getB2CRedirectUrl() {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3000';
  }

  switch (process.env.REACT_APP_NAME) {
    case 'test-2':
      return 'https://c61-test-2-petcloud-react.azurewebsites.net';
    case 'test-3':
      return 'https://c61-test-3-petcloud-react.azurewebsites.net';
    case 'test-4':
      return 'https://c61-test-4-petcloud-react.azurewebsites.net';
    case 'Prod':
      return 'https://www.mypetcloud.com';
    case 'CPSC':
      return 'https://c61-cpsc-petcloud-react.azurewebsites.net';
    default:
      return 'https://c61-test-3-petcloud-react.azurewebsites.net';
  }
}

/**
 * Retrieves the first key within the provided object that matches the given value.
 * If no matching value is found, an empty string is returned.
 *
 * @param {Object} object - The object to search through.
 * @param {*} value - The value to match against the object's keys.
 * @returns {string} The key that matches the given value, or an empty string if no match is found.
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value) || '';
}

export function getBrandNameByChannelId(channelId) {
  return getKeyByValue(MARKETING_CHANNEL_ID, channelId);
}

/**
 * Transform every object keys to PascalCase from the provided object.
 * The main purpose is to handle endpoint responses.
 *
 * @param {Object} originalObject - The object to update their key names.
 * @returns {Object} Original object with updated keys into PascalCase standard.
 */
const textPascalCaseFormat = (text) => startCase(camelCase(text)).replace(/ /g, '');

export function objectToPascalCase(originalObject) {
  if (Array.isArray(originalObject)) {
    return originalObject.map((value) => objectToPascalCase(value));
  }
  if (typeof originalObject === 'object') {
    return Object.keys(originalObject).reduce((acc, current) => {
      const key = textPascalCaseFormat(current);
      const value = originalObject[current];
      acc[key] =
        value !== null && typeof value === 'object' ? objectToPascalCase(value) : value;
      return acc;
    }, {});
  }
  return originalObject;
}
