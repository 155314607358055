import { call, put, select } from 'redux-saga/effects';
import {
  attemptPrivateToken,
  attemptTemporaryToken,
  bannerVisibleChanged,
  checkIfHasPrimaryVet,
  customerInformationFailed,
  customerInformationLoaded,
  loadDataForPrivateToken,
  loadMultiModalData,
  logIn,
  logOut,
  privateTokenCompleted,
  privateTokenFailed,
  saveImpersonateCustomerId,
  saveUserInfo,
  saveUserTermsOfService,
  sessionError,
  setPrivateToken,
  setTemporaryToken,
  showGenericError,
  startupFinished,
  temporaryTokenCompleted,
  temporaryTokenFailed,
  termsOfServiceAccepted,
  tokenRefreshed,
} from '../actions';
import { POLICY_STATUS } from '../constants';
import {
  apiCallWithApiKey,
  get,
  getData,
  post,
  removeData,
  saveData,
  signIn,
  callRefreshToken,
} from '../services/api';
import {
  CLAIMS_API_TOKEN,
  CUSTOMER_API_TOKEN,
  IMPERSONATE_EMAIL,
  USER_COMPANY_KEY,
  USER_INFO_KEY,
} from '../services/constants';
import { maskPhoneNumber } from '../services/utils';
import { getPolicySummary } from './policies';
import { useCustomerApiV1ForSagas } from '../services/featureFlagsForSagas';

export function* loadCachedUserInfo() {
  const data = yield call(getData, USER_INFO_KEY);
  const userInfo = JSON.parse(data);

  if (userInfo) {
    yield put(saveUserInfo({
      ...userInfo,
      OtherPhoneNumber: userInfo.OtherPhoneNumber
        && maskPhoneNumber(userInfo.OtherPhoneNumber),
      PhoneNumber: maskPhoneNumber(userInfo.PhoneNumber),
    }));
  }
}

function* removeAllData() {
  yield call(removeData, USER_INFO_KEY);
  yield call(removeData, 'token');
  yield call(removeData, 'refresh_token');
  yield call(removeData, 'banner_preferences');
  yield call(removeData, CUSTOMER_API_TOKEN);
  yield call(removeData, CLAIMS_API_TOKEN);
}

export function* checkSession() {
  const token = yield call(getData, 'session-token');

  if (token) {
    yield put(logIn({ token }));
  }
}

export function* syncUserInformationStoreWithRedux() {
  const store = yield select(({ accountLogin, personalInformation }) => ({
    accountLogin,
    personalInformation,
  }));

  if (store.accountLogin.userInfo && store.personalInformation.userInfo) {
    const userInfo = {
      ...store.accountLogin.userInfo,
      ...store.personalInformation.userInfo,
    };

    yield call(saveData, USER_INFO_KEY, JSON.stringify(userInfo));
  }
}

export function* getUserInformation(dispatch) {
  const token = yield call(getData, 'token');

  if (token) {
    const response = yield call(get, dispatch, 'api/Account/personalInfo');
    if (response.success && response.Data) {
      const personalInfoNullSafe = {
        ...response.Data,
        Address: {
          ...response.Data.Address,
          Line1: response.Data.Address.Line1 || '',
          Line2: response.Data.Address.Line2 || '',
          Phone1: response.Data.Address.Phone1 || '',
          Phone2: response.Data.Address.Phone2 || '',
        },
        OtherPhoneNumber: response.Data.OtherPhoneNumber || '',
        PhoneNumber: response.Data.PhoneNumber || '',
      };

      const personalInformation = {
        ...personalInfoNullSafe,
        OtherPhoneNumber: personalInfoNullSafe.OtherPhoneNumber
          && maskPhoneNumber(personalInfoNullSafe.OtherPhoneNumber),
        PhoneNumber: personalInfoNullSafe.PhoneNumber
          && maskPhoneNumber(personalInfoNullSafe.PhoneNumber),
      };

      yield put(saveUserInfo(personalInformation));
      yield call(saveData, USER_INFO_KEY, JSON.stringify(personalInfoNullSafe));
      yield call(saveData, USER_COMPANY_KEY,
        JSON.stringify(personalInfoNullSafe.Company));
      yield put(checkIfHasPrimaryVet());

      const impersonateEmail = getData(IMPERSONATE_EMAIL);

      if (impersonateEmail) {
        yield put(saveImpersonateCustomerId({
          adminEmail: impersonateEmail,
          customerId: personalInformation.CustomerGuid || '',
        }));
      }
    } else if (!response.error) {
      yield call(removeAllData);
    }
  }

  yield put(startupFinished());
}

export function* getUserTermsOfService(dispatch) {
  const token = yield call(getData, 'token');

  if (token) {
    const response = yield call(get, dispatch, 'api/User/UserHasTermOfService');
    if (response.success && response.Data) {
      yield put(saveUserTermsOfService(response.Data));
    }
  }
}

export function* doLogout(dispatch) {
  yield call(post, dispatch, 'api/Authentication/LogOut');
  yield put(logOut());
  yield call(removeAllData);
}

function* handleB2CToken({
  accessToken = '',
  errorMessage = '',
  refreshTokenValue = '',
  success = true,
}) {
  if (success) {
    if (accessToken) {
      yield call(saveData, 'token', accessToken);
      yield call(saveData, 'refresh_token', refreshTokenValue);
      yield call(getUserTermsOfService);
      yield call(getUserInformation);

      const payload = {
        access_token: accessToken,
        refresh_token: refreshTokenValue,
        success,
        token_type: 'bearer',
      };

      yield put(logIn(payload));
    } else {
      yield put(sessionError(errorMessage));
    }
  } else {
    yield put(sessionError('An error has ocurred'));
  }
}

export function* doLogin(action) {
  const { payload: { b2cToken, email, password } } = action;
  const url = b2cToken ? '/api/Authentication/Token/RegisterB2C'
    : '/token';

  const requestBody = `username=${email}&password=${password}`;

  const requestB2CBody = b2cToken ? {
    Grant_Type: 'password',
    Impersonate: false,
    OsType: 'petcloud-web',
    Password: password,
  } : '';

  const res = yield call(signIn,
    {
      b2cToken,
      requestB2CBody,
      requestBody,
      url,
    });

  yield call(handleB2CToken, {
    accessToken: b2cToken,
    errorMessage: res.Message,
    refreshTokenValue: '',
    success: res.success,
  });
}

export function* doLoginImpersonate(action) {
  const { payload: { b2cToken, CustomerGuid, email, password } } = action;
  const url = b2cToken ? '/api/Authentication/Token/RegisterB2C'
    : '/token';

  const requestBody = `username=${email}&password=${password}`
    + `&impersonate=true&CustomerGuid=${CustomerGuid}`;

  const requestB2CBody = b2cToken ? {
    CustomerGuid,
    Grant_Type: 'password',
    Impersonate: true,
    OsType: 'petcloud-web',
    Password: password,
  } : '';

  const res = yield call(signIn,
    {
      b2cToken,
      requestB2CBody,
      requestBody,
      url,
    });

  yield put(saveImpersonateCustomerId({
    adminEmail: email,
    customerId: CustomerGuid,
  }));

  yield call(handleB2CToken, {
    accessToken: b2cToken,
    errorMessage: res.Message,
    refreshTokenValue: '',
    success: res.success,
  });
}

export function* doBannerVisibleChanged(_, action) {
  const { payload: { visible } } = action;
  const bannerPreferences = {
    visible,
  };
  yield call(saveData, 'banner_preferences', JSON.stringify(bannerPreferences));
}

export function* loadCachedBannerVisible() {
  const data = yield call(getData, 'banner_preferences');
  const { visible } = JSON.parse(data) || { visible: true };
  yield put(bannerVisibleChanged({ visible: !!visible }));
}

function* doLoadMultiModalData() {
  const store = yield select(({ policies, session }) => ({
    policies,
    session,
  }));
  const { CustomerId } = store.session.userInfo;
  const policy = store.policies.allPolicies.find((item) => item.Policy.Status
    === POLICY_STATUS.active);

  if (policy) {
    yield put(loadMultiModalData({ CustomerId }));
  }
}

export function* getTemporaryToken(dispatch) {
  yield put(attemptTemporaryToken());
  const response = yield call(
    get,
    dispatch,
    'api/Authentication/TemporaryToken',
  );

  const { IsValid, Data } = response;

  if (IsValid && Data) {
    yield put(setTemporaryToken({ publicToken: Data }));
    yield call(saveData, 'public_token', Data);
    yield put(temporaryTokenCompleted());
  } else {
    yield put(temporaryTokenFailed());
    yield put(showGenericError(true));
  }
}

export function* loadDataPrivateToken(dispatch) {
  yield call(getUserInformation, dispatch);
  yield call(getPolicySummary, dispatch);
  yield call(doLoadMultiModalData, dispatch);
  yield put(privateTokenCompleted());
}

export function* getPrivateToken(dispatch, { payload }) {
  const { publicToken } = payload;
  yield put(attemptPrivateToken());
  const response = yield call(
    apiCallWithApiKey,
    `api/Authentication/TemporaryToken/Validate/${publicToken}`,
    {},
    dispatch,
  );

  const { Data, IsValid } = response;

  if (IsValid && Data) {
    yield put(setPrivateToken({ privateToken: Data }));
    yield call(saveData, 'token', Data);
    yield put(loadDataForPrivateToken());
  } else {
    yield put(privateTokenFailed());
    yield put(showGenericError(true));
  }
}

export function* refreshToken() {
  const response = yield call(callRefreshToken);
  if (response.success) {
    yield put(tokenRefreshed());
  } else {
    yield call(removeAllData);
    yield put(logOut());
  }
}

export function* convertCustomerId(dispatch, action) {
  const customerId = action.payload;
  const response = yield call(
    apiCallWithApiKey,
    `api/Customer/CustomerIdDecrypter/${customerId}`,
    { method: 'POST' },
    dispatch,
  );

  const { Data, IsValid } = response;

  if (IsValid && Data) {
    yield put(saveImpersonateCustomerId({
      adminEmail: '',
      customerId: Data,
    }));
  } else {
    yield put(showGenericError(true));
  }
}

export function* acceptTermsOfService(dispatch) {
  const store = yield select(({ session }) => ({ session }));
  const { termsOfServiceVersionId: TermsVersionId } = store.session;
  const response = yield call(
    post,
    dispatch,
    'api/User/AcceptTermOfService',
    { TermsVersionId },
  );
  const { IsValid, Data } = response;

  if (IsValid && Data) {
    yield put(termsOfServiceAccepted());
  } else {
    yield put(showGenericError(true));
  }
}

export function* loadCustomerInformation(dispatch, { payload }) {
  const useCustomerApiV1 = yield useCustomerApiV1ForSagas();
  const { marketingChannelId } = payload;

  const urlService = useCustomerApiV1 ? 'customer' : 'api';
  const url = `${urlService}/`
    + `Customer/CustomerMarketChannelDetail/${marketingChannelId}`;
  const response = yield call(get, dispatch, url);

  if (response.IsValid) {
    yield put(customerInformationLoaded({
      addressPart1: response.Data?.AddressPart1,
      addressPart2: response.Data?.AddressPart2,
      displayName: response.Data?.DisplayName,
      email: response.Data?.Email,
      fullName: response.Data?.FullName,
      id: response.Data?.Id,
      originId: response.Data?.OriginId,
      phoneNumber1: response.Data?.PhoneNumber1,
      phoneNumber2: response.Data?.PhoneNumber2,
    }));
  } else {
    yield put(customerInformationFailed());
  }
}
