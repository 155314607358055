import React from 'react';
// import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Image from 'react-bootstrap/Image';
// import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';

import { HomeScreen } from '../home';
import PageNotFound from '../pageNotFound/PageNotFound';
import SideMenu from '../sideMenu/SideMenu';
import InsuranceNavigation from '../insurance/InsuranceNavigation';
import { FileClaimbotScreen } from '../claims/claimbot/FileClaimbotScreen';
import InboxAndDocsScreen from '../inboxAndDocs/InboxAndDocsScreen';
import { MyAccountNavigation } from '../myAccount/MyAccountNavigation';
import { NavigationMyPets } from '../myPets/NavigationMyPets';
import GenericError from '../genericError/GenericError';
import { FoundLostPet } from '../foundLostPet/FoundLostPet';
import { AcceptTermsOfServiceModal } from '../settings';

import menuIcon from '../../assets/sideMenu/burger-menu.svg';
import * as routes from './routes.constants';
import Header from './Header';
import './SessionRouter.css';

const sideMenuMinMediaQuery = { lg: '(max-width: 1024px)' };

class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.screenWindow = window;
    this.screenMatch = this.screenWindow.matchMedia(sideMenuMinMediaQuery.lg);
    this.screenMatch.addListener(this.onScreenSizeChange);

    this.state = {
      contactUsVisible: false,
      leftMenuOpen: !this.screenMatch.matches,
      myAccountMenuOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.showGenericError && this.props.showGenericError) {
      this.props.history.push(routes.ERROR);
    }
  }

  toggleSidebar = () => {
    this.setState((prevState) => ({ leftMenuOpen: !prevState.leftMenuOpen }));
  };

  onScreenSizeChange = (event) => {
    this.setState(() => ({ leftMenuOpen: !event.matches }));
  };

  closeMenu = () => {
    if (this.screenMatch.matches && this.state.leftMenuOpen) {
      this.setState(() => ({ leftMenuOpen: false }));
    }
  };

  toggleMyAccountMenu = () => {
    this.addDOMEventListener();
    this.setState((prevState) => ({
      myAccountMenuOpen: !prevState.myAccountMenuOpen,
    }));
  };

  setContactUsVisible = (visible) => {
    this.setState(() => ({
      contactUsVisible: visible,
    }));
  };

  comingSoon = () => <p>Coming Soon</p>;

  render() {
    const { leftMenuOpen, contactUsVisible } = this.state;

    return (
      <>
        <Header />

        <AcceptTermsOfServiceModal />

        <div className="Layout" id="layout">
          <div className={`${leftMenuOpen ? 'open' : 'closed'}`} id="left">
            <Image
              className={'Side-menu-burger-icon'
                + `${leftMenuOpen ? ' Side-menu-burger-open' : ''}`}
              onClick={this.toggleSidebar}
              src={menuIcon}
            />

            <div
              className={`sidebar ${leftMenuOpen ? 'open' : 'closed'}`
                + ' Sidebar'}
            >
              <div className="content">
                <SideMenu
                  contactUsVisible={contactUsVisible}
                  onMenuItemPress={this.closeMenu}
                  setContactUsVisible={this.setContactUsVisible}
                />
              </div>
            </div>
          </div>

          <Row
            className="Sidebar-overlay d-xl-none"
            onClick={this.toggleSidebar}
            style={{ display: `${leftMenuOpen ? 'initial' : 'none'}` }}
          />

          <div className="Main-content">

            <div className="Main-content">
              <div className="Content">
                <Switch>
                  <Route exact path={['/', routes.HOME]}>
                    <HomeScreen />
                  </Route>

                  <Route path={[routes.B2C_TOKEN_REGISTER]}>
                    <Redirect to="/" />
                  </Route>

                  <Route path={routes.INBOX_DOCS}>
                    <InboxAndDocsScreen />
                  </Route>

                  <Route path={routes.MY_ACCOUNT}>
                    <MyAccountNavigation />
                  </Route>

                  <Route exact path={routes.MY_PETS}>
                    <Redirect to="/insurance" />
                  </Route>

                  <Route path={[`${routes.MY_PETS}/:id`, routes.MY_PETS]}>
                    <NavigationMyPets
                      contactUsVisible={contactUsVisible}
                      setContactUsVisible={this.setContactUsVisible}
                    />
                  </Route>

                  <Route path={[routes.INSURANCE, routes.CHANGE_COVERAGE]}>
                    <InsuranceNavigation />
                  </Route>

                  <Route path={routes.CLAIMS}>
                    <InsuranceNavigation />
                  </Route>

                  <Route path={`${this.props.match.path}/details`}>
                    <InsuranceNavigation />
                  </Route>

                  <Route path={routes.FILE_CLAIMBOT}>
                    <FileClaimbotScreen />
                  </Route>

                  {/* TODO uncomment when is ready to prod  */}
                  {/* <Route path="/vets">
                    {this.comingSoon}
                  </Route> */}

                  <Route path={routes.ERROR}>
                    <GenericError />
                  </Route>

                  <Route exact path={routes.FOUND_LOST_PET}>
                    <FoundLostPet />
                  </Route>

                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { userInfo } = state.session;
  const { showGenericError } = state.common;

  return { showGenericError, userInfo };
}

export default withRouter(connect(mapStateToProps)(AppRoutes));
