import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import ClaimsDetails from '../claims/claimsDetails/ClaimsDetails';
import PageNotFound from '../pageNotFound/PageNotFound';
import * as routes from '../navigation/routes.constants';
import { useMixpanel, useWebviewMultibrand } from '../../hooks';
import { EV53 } from '../../services/mixpanel';
import { getBrandNameByChannelId } from '../../services/utils';
import { loadMultiModalData, youngPetModalOpen } from '../../actions';
import {
  MINIMUM_DAYS_REQUIRED_FOR_QUOTE,
  WEB_VIEW_TYPES,
} from '../../constants';
import { InsuranceScreen } from './InsuranceScreen';
import { PolicySummary } from './PolicySummary';
import ClaimsScreenComponent from '../claims/ClaimsScreenComponent';
import {
  ChangeCoverageInsuranceScreen,
} from './changeCoverage/ChangeCoverageInsuranceScreen';
import { OtherPolicies } from './OtherPolicies';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';
import { OrcaGetQuoteFrame } from './OrcaGetQuoteFrame';
import { AddPetScreen } from './AddPetScreen';
import { AddPetScreenIFrame } from './AddPetScreenIFrame';

const InsuranceNavigation = () => {
  const match = useRouteMatch();
  const { sendEvent } = useMixpanel();
  const { personalInformation, policies, orcaInsurance } = useSelector(
    (state) => ({
      orcaInsurance: state.orcaInsurance,
      personalInformation: state.personalInformation,
      policies: state.policies,
      session: state.session,
    }), shallowEqual,
  );
  const {
    getWebView,
    isLoading,
    marketChannelId,
    reset,
    url,
  } = useWebviewMultibrand();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showAddCoverageModal, setShowAddCoverageModal] = useState(false);
  const [showInvalidBreedModal, setShowInvalidBreedModal] = useState(false);
  const [currentPetId, setCurrentPetId] = useState(null);
  const { userInfo: { Companies, ...userInfo } } = personalInformation;
  const { allPets, multiModalData } = policies;

  useEffect(() => {
    if (Object.entries(multiModalData).length === 1) {
      dispatch(loadMultiModalData({ CustomerId: userInfo.CustomerId }));
    }
  }, [dispatch, userInfo.CustomerId]);

  useEffect(() => {
    if (isLoading || !currentPetId || !url || !marketChannelId) {
      return;
    }

    if (marketChannelId === MARKETING_CHANNEL_ID.figo) {
      // Figo getQuote
      window.open(url, '_blank');

      reset();
    } else {
      // Orca getQuote
      history.push(`${routes.INSURANCE}/getQuote`);
    }
  }, [currentPetId, isLoading, url, marketChannelId]);

  const getFigoQuote = useCallback((petId) => {
    const foundPet = allPets.find((pet) => pet.PetId === petId);
    const {
      getQuoteValidation: {
        petValidBreed: isInvalidBreed,
      },
    } = orcaInsurance;
    if (isInvalidBreed) {
      setShowInvalidBreedModal(true);
    } else if (MINIMUM_DAYS_REQUIRED_FOR_QUOTE > foundPet?.AgeInDays) {
      dispatch(youngPetModalOpen());
    } else {
      getWebView({
        companyId: MARKETING_CHANNEL_ID.figo,
        petId,
        webViewType: WEB_VIEW_TYPES.figoGetQuote,
      });
    }
  }, [dispatch, allPets]);

  const handleGetQuoteByBrand = useCallback(({
    marketingChannelId,
    petId,
  }) => {
    setCurrentPetId(petId);
    sendEvent(EV53({
      customerId: personalInformation.userInfo.CustomerId,
      petId,
      policyBrand: getBrandNameByChannelId(marketingChannelId),
      source: 'pet cloud web',
    }));

    switch (marketingChannelId) {
      case MARKETING_CHANNEL_ID.akc:
      case MARKETING_CHANNEL_ID.ppi:
      case MARKETING_CHANNEL_ID.spot:
        getWebView({
          companyId: marketingChannelId,
          petId,
          webViewType: WEB_VIEW_TYPES.newQuoteForPet,
        });
        break;
      case MARKETING_CHANNEL_ID.ppiOnePack:
        setShowAddCoverageModal(true);
        break;
      case MARKETING_CHANNEL_ID.figo:
        getFigoQuote(petId);
        break;
      default:
        break;
    }
  }, [getWebView, getFigoQuote]);

  const closeAddCoverageModal = useCallback(() => {
    setShowAddCoverageModal(false);
  }, []);

  const closeInvalidBreedModal = useCallback(() => {
    setShowInvalidBreedModal(false);
  }, []);

  return (
    <div>
      <div className="Content-scrollable">
        <Switch>
          <Route
            path={`${routes.CHANGE_COVERAGE_WEB}/:policyNumber`}
          >
            <ChangeCoverageInsuranceScreen />
          </Route>

          <Route
            exact
            path={routes.INSURANCE}
          >
            <InsuranceScreen />
          </Route>

          <Route path={`${routes.INSURANCE}/:petId/morePolicies`}>
            <OtherPolicies />
          </Route>

          <Route exact path={`${routes.INSURANCE}/getQuote`}>
            <OrcaGetQuoteFrame />
          </Route>

          <Route exact path={routes.ADD_ORCA_PET}>
            <AddPetScreen />
          </Route>

          <Route exact path={routes.ADD_ORCA_PET_IFRAME}>
            <AddPetScreenIFrame />
          </Route>

          <Route
            path={[
              `${routes.INSURANCE}/:petId/:policyId`,
              `${routes.INSURANCE}/:petId`,
            ]}
          >
            <PolicySummary
              onCloseInvalidBreedModal={closeInvalidBreedModal}
              onClosePPIGroupModal={closeAddCoverageModal}
              onGetQuoteByBrand={handleGetQuoteByBrand}
              showInvalidBreedModal={showInvalidBreedModal}
              showPPIGroupModal={showAddCoverageModal}
            />
          </Route>

          <Route path={routes.CHANGE_COVERAGE}>
            <Redirect
              to={`${routes.CHANGE_COVERAGE_WEB}/${match.params
                .policyNumber}`}
            />
          </Route>

          <Route
            exact
            path={routes.CLAIMS}
          >
            <ClaimsScreenComponent />
          </Route>

          <Route
            exact
            path={`${match.path}/details`}
          >
            <ClaimsDetails />
          </Route>

          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default InsuranceNavigation;
