import { useCallback, useState } from 'react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  doLogin,
  doLoginImpersonate,
  logOut,
  tokenClaimLoaded,
  tokenCustomerLoaded,
} from '../actions';
import {
  b2cPolicies,
  claimBotScope,
  claimScope,
  customerScope,
  documentsScope,
} from '../authConfigB2C';
import { delay } from '../services/utils';
import { getData, removeAllData, saveData } from '../services/api';
import * as routes from '../components/navigation/routes.constants';
import { CLAIMS_API_TOKEN, CUSTOMER_API_TOKEN } from '../services/constants';

const useB2CInfo = () => {
  const { accounts, inProgress, instance } = useMsal();
  const [b2cToken, setB2cToken] = useState();
  const dispatch = useDispatch();
  const store = useSelector(({ session }) => ({ session }), shallowEqual);

  const tokenRequestB2C = useCallback(async ({
    authority = b2cPolicies.authorities.signUpSignIn.authority,
    scopes = [],
    tokenKey = '',
  }) => {
    const apiResponse = await instance.acquireTokenSilent({
      account: accounts[0],
      authority,
      scopes,
    });

    const apiToken = apiResponse?.accessToken || apiResponse?.idToken;

    saveData(tokenKey, apiToken);

    if (tokenKey === CUSTOMER_API_TOKEN) {
      dispatch(tokenCustomerLoaded());
    } else if (tokenKey === CLAIMS_API_TOKEN) {
      dispatch(tokenClaimLoaded());
    }

    return apiToken;
  }, [accounts, instance]);

  /**
   * Function to get every token for each api
   * with their required scopes.
   * @param {Array} scopes required scopes for desired api
   * @param {String} tokenKey custom keyname to store and use on token
   * @param {String} authority to request token depending user flow
   */
  const getAllTokens = useCallback(async ({ isImpersonate = false }) => {
    const authority = isImpersonate
      ? b2cPolicies.authorities.impersonation.authority
      : b2cPolicies.authorities.signUpSignIn.authority;

    await Promise.all([
      tokenRequestB2C({
        authority,
        scopes: [customerScope, documentsScope],
        tokenKey: CUSTOMER_API_TOKEN,
      }),
      tokenRequestB2C({
        authority,
        scopes: [claimBotScope, claimScope],
        tokenKey: CLAIMS_API_TOKEN,
      }),
    ]);
  }, [instance, tokenRequestB2C]);

  const loginB2C = useCallback(({ isImpersonate = false }) => {
    const tokenRequest = {
      account: accounts[0],
      authority: isImpersonate ? b2cPolicies.authorities.impersonation.authority
        : b2cPolicies.authorities.signUpSignIn.authority,
      prompt: 'login',
    };

    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenRedirect(tokenRequest)
        .then((tokenResponse) => {
          setB2cToken(tokenResponse.accessToken
            || tokenResponse.idToken);
          instance.setActiveAccount(tokenResponse.account);
          const action = isImpersonate ? doLoginImpersonate : doLogin;

          if (tokenResponse) {
            dispatch(action({
              b2cToken: tokenResponse.accessToken
                || tokenResponse.idToken,
              CustomerGuid: '',
              email: '',
              keepSigned: false,
              password: '',
            }));
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenRedirect(tokenRequest)
              .then((tokenResponse) => {
                setB2cToken(tokenResponse.accessToken
                  || tokenResponse.idToken);
                instance.setActiveAccount(tokenResponse.account);

                const action = isImpersonate ? doLoginImpersonate : doLogin;

                if (tokenResponse) {
                  dispatch(action({
                    b2cToken: tokenResponse.accessToken
                      || tokenResponse.idToken,
                    CustomerGuid: '',
                    email: '',
                    keepSigned: false,
                    password: '',
                  }));
                }
              });
          }
        });
    }
  }, [accounts, dispatch, inProgress, instance]);

  const logoutB2C = useCallback(async () => {
    const { isImpersonate } = store.session;
    let postLogoutRedirectUri = window.location.origin;

    if (isImpersonate) {
      postLogoutRedirectUri = `${window.location.origin}${routes.IMPERSONATE}`;
    }

    const b2cAuthority = isImpersonate
      ? b2cPolicies.authorities.impersonation.authority
      : b2cPolicies.authorities.signUpSignIn.authority;

    const logoutRequest = {
      account: instance.getActiveAccount(),
      authority: b2cAuthority,
      postLogoutRedirectUri,
    };

    dispatch(logOut());
    removeAllData();

    await delay(3000);

    const token = await getData('token');

    if (token) {
      logoutB2C();
    } else {
      instance.logoutRedirect(logoutRequest);
    }
  }, [instance, store.session.isImpersonate]);

  const forgotPasswordB2C = useCallback(() => {
    window.location.replace(b2cPolicies.authorities.forgotPassword.redirectUrl);
  }, [window.location]);

  return {
    b2cToken,
    forgotPasswordB2C,
    getAllTokens,
    loginB2C,
    logoutB2C,
    tokenRequestB2C,
  };
};

export { useB2CInfo };
