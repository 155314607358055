import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MY_ACCOUNT, WEB_VIEW_TYPES } from '../../../../constants';
import { MY_ACCOUNT as MY_ACCOUNT_ROUTE }
from '../../../navigation/routes.constants.js';
import { MARKETING_CHANNEL_ID } from '../../../../theme/theme.constants.js';
import { useWebviewMultibrand }
from '../../../../hooks/useWebViewMultibrand.js';

const useBillingAndPayments = ({
  policyInfo = {},
}) => {
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const {
    getWebView,
    title,
    url,
  } = useWebviewMultibrand();

  const onOpenPaymentMethod = useCallback(() => {
    if (policyInfo.MarketChannelId !== MARKETING_CHANNEL_ID.figo) {
      getWebView({
        companyId: policyInfo.MarketChannelId,
        policyId: policyInfo.Id,
        policyNumber: policyInfo.PolicyDisplayNumber,
        webViewType: WEB_VIEW_TYPES.billingAndPayment,
      });
      return;
    }

    history.push({
      pathname: MY_ACCOUNT_ROUTE,
      state: {
        breadCrumbId: MY_ACCOUNT.billingAndPaymentDetails,
        breadCrumbName: t('breadCrumbs.breadCrumbPolicy', {
          ns: 'myAccount',
          policyNumber: policyInfo.PolicyDisplayNumber,
        }),
        breadCrumbNameExtraData: {
          marketingChannelId: policyInfo.MarketChannelId,
          policyNumber: policyInfo.PolicyDisplayNumber,
        },
      },
    });
  }, [url]);

  useEffect(() => {
    // Go to Billing and payments for nonfigo policies
    if (url && policyInfo
      && policyInfo.MarketChannelId !== MARKETING_CHANNEL_ID.figo) {
      history.push({
        pathname: MY_ACCOUNT_ROUTE,
        state: {
          breadCrumbId: MY_ACCOUNT.billingAndPaymentDetails,
          breadCrumbName: t('breadCrumbs.breadCrumbPolicy', {
            ns: 'myAccount',
            policyNumber: policyInfo.PolicyDisplayNumber,
          }),
          breadCrumbNameExtraData: {
            marketingChannelId: policyInfo.MarketChannelId,
            title,
            url,
          },
        },
      });
    }
  }, [url]);

  return {
    onOpenPaymentMethod,
  };
};

export { useBillingAndPayments };
