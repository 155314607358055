import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PET_TYPE_MULTIBRAND } from '../../../constants';
import dogDefaultImage
from '../../../assets/billingPayments/dog-placeholder.png';
import catDefaultImage
from '../../../assets/billingPayments/cat-placeholder.png';

import './PetCard.css';

const PetCard = ({
  age = '',
  breed = '',
  classContainer = '',
  gender = '',
  name = '',
  onClick = () => { },
  picture = null,
  type = '',
  weight = '',
}) => {
  const { t } = useTranslation('insuranceScreen');
  const isDog = type === PET_TYPE_MULTIBRAND.DOG;

  const petCardImage = useMemo(() => {
    if (picture) {
      return picture;
    }

    return isDog ? dogDefaultImage : catDefaultImage;
  }, [isDog, picture]);

  return (
    <button
      className={`Pet-card-container ${classContainer}`}
      onClick={onClick}
      type="button"
    >
      <div className="Pet-card-top-container">
        <img alt="" src={petCardImage} />

        <div className="Pet-card-top-info-container">
          <h3 className="Pet-card-name">{name}</h3>

          <span className="Pet-card-breed">{breed}</span>
        </div>
      </div>

      <div className="Pet-card-bottom-container">
        <div className="Pet-card-age-container">
          <span className="Pet-card-info-title">
            {t('multiplePets.ageTitle')}
          </span>

          <span className="Pet-card-info-subtitle">
            {(t('multiplePets.ageValue', { age }))}
          </span>
        </div>

        <div className="Pet-card-gender-container">
          <span className="Pet-card-info-title">
            {t('multiplePets.genderTitle')}
          </span>

          <span className="Pet-card-info-subtitle-gender">{gender}</span>
        </div>

        <div className="Pet-card-weight-container">
          <span className="Pet-card-info-title">
            {t('multiplePets.weightTitle')}
          </span>

          <span className="Pet-card-info-subtitle">
            {weight
              ? (t('multiplePets.weightValue', { weight }))
              : '-'}
          </span>
        </div>
      </div>
    </button>
  );
};

export { PetCard };
