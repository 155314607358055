import { call, put } from 'redux-saga/effects';
import { apiCallWithApiKey, getApiV2, postApiV2 } from '../services/api';
import {
  searchPetTagAttempt,
  searchPetTagFailed,
  searchPetTagFound,
  sendMessageFailed,
  sendMessageSucceed,
} from '../actions';
import {
  useCustomerApiV2MyPetsForSagas,
} from '../services/featureFlagsForSagas';

export function* doSearchPetTag(dispatch, action) {
  const { petTag } = action.payload;

  const useCustomerApiV2MyPets = yield useCustomerApiV2MyPetsForSagas();
  let petTagResponse = null;

  yield put(searchPetTagAttempt());

  if (useCustomerApiV2MyPets) {
    const response = yield call(
      getApiV2,
      dispatch,
      `customer/pets/tags/${petTag}`,
    );

    petTagResponse = response.success ? {
      Data: {
        BreedName: response.breedName,
        Cit: response.city,
        ImageUrl: response.imageUrl,
        Note: response.note,
        PetAge: response.petAge,
        PetName: response.petName,
        Type: response.type,
      },
      success: true,
    } : null;
  } else {
    petTagResponse = yield call(
      apiCallWithApiKey,
      `api/Customer/ValidatePetTag/${encodeURI(petTag)}`,
    );
  }

  if (petTagResponse?.success) {
    yield put(searchPetTagFound(petTagResponse?.Data));
  } else {
    const menssage = petTagResponse?.Message || 'Unexpected Error';
    yield put(searchPetTagFailed(menssage));
  }
}

export function* contactPetFamily(dispatch, { payload }) {
  const useCustomerApiV2MyPets = yield useCustomerApiV2MyPetsForSagas();
  const { lostPetTag, message, phoneNumber, finderName } = payload;
  let response = null;

  if (useCustomerApiV2MyPets) {
    const body = {
      finderName,
      message,
      petTag: lostPetTag,
      phone: phoneNumber,
    };

    response = yield call(
      postApiV2,
      dispatch,
      'customer/notifications/found-pet',
      body,
    );
  } else {
    const url = 'api/Customer/SendSMSFoundPet';
    const requestOptions = {
      body: {
        FinderName: finderName,
        Message: message,
        PetTag: lostPetTag,
        Phone: phoneNumber,
      },
      method: 'POST',
    };

    response = yield call(apiCallWithApiKey, url, requestOptions, dispatch);
  }

  if (response.success) {
    yield put(sendMessageSucceed());
  } else {
    yield put(sendMessageFailed());
  }
}
